import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';
import { Link } from 'react-router-dom';

// import '..WhyCardano/WhyCardano'
import whyHeroImg from '../images/whyHeroImg.jpg'


const WhyCardanoBottom = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const whyBottom = useRef();

    useEffect(() => {
        if (whyBottom !== state?.refs?.whyBottom) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'whyBottom': whyBottom } })
        }
    }, [state])


    return (
        <div ref={whyBottom} className='whyBottomSection' style={{
        }}>
            <Row justify='center' className='whyBottomSubDiv' style={{ height: '100%', paddingTop: '100px', paddingBottom: '100px' }}>
                <Col span={20} style={{ height: '100%' }}>


                    <Link to='/'>
                        <div className='buyNFTButton'>
                            Homepage
                        </div>
                    </Link>


                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoBottom


