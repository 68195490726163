import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import HeaderSection from '../components/Header';
import logo from '../logo.svg';
import Hero from '../components/Hero';
import SideScrollHero from '../components/SideScrollHero';
import Roadmap from '../components/Roadmap';
import Faq from '../components/Faq';
import Art from '../components/Art';
import Team from '../components/Team';
import VideoOne from '../components/VideoOne';
import TimerBar from '../components/TimerBar';
import HowItWorks from '../components/HowItWorks';
import LockedContent from '../components/LockedContent';
import IntroSection from '../components/IntroSection';
import IntroSectionTwo from '../components/IntroSectionTwo';
import IntroSectionThree from '../components/IntroSectionThree';
import BuyNFTSectionOne from '../components/BuyNFTSectionOne';
import ExplainSectionOne from '../components/ExplainSectionOne';
import ExplainSectionTwo from '../components/ExplainSectionTwo';
import ExplainSectionThree from '../components/ExplainSectionThree';
import OutroMessageSection from '../components/OutroMessageSection';
import TelegramSection from '../components/TelegramSection';

import WhyCardanoHero from '../WhyCardano/WhyCardanoHero';
import WhyCardanoHeroBar from '../WhyCardano/WhyCardanoHeroBar'
import WhyCardanoSection1 from '../WhyCardano/WhyCardanoSection1'
import WhyCardanoSection2 from '../WhyCardano/WhyCardanoSection2'
import WhyCardanoSection3 from '../WhyCardano/WhyCardanoSection3'
import WhyCardanoSection4 from '../WhyCardano/WhyCardanoSection4'
import WhyCardanoSection5 from '../WhyCardano/WhyCardanoSection5'
import WhyCardanoSection6 from '../WhyCardano/WhyCardanoSection6'
import WhyCardanoSection7 from '../WhyCardano/WhyCardanoSection7'
import WhyCardanoBottom from '../WhyCardano/WhyCardanoBottom';


import { BackTop } from 'antd';

const WhyCardanoPage = ({ state, dispatch, stateTwo, dispatchTwo }) => {


  return (
    <div >
      <BackTop />
      <WhyCardanoHero state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoHeroBar state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoSection1 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoSection2 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoSection3 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoSection4 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoSection5 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      <WhyCardanoSection6 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      {/* <WhyCardanoSection7 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
      <WhyCardanoBottom state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />


    </div>
  )
}

export default WhyCardanoPage