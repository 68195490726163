import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import Hero from '../components/Hero';
import WhyCardanoBottom from '../WhyCardano/WhyCardanoBottom';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';


import { BackTop } from 'antd';

const TermsConditions = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    return (
        <div style={{ width: '100%' }}>
            <BackTop />

            {/* <Hero state={state} dispatch={dispatch} /> */}
            <Row style={{ width: '100%', height: '150px', backgroundColor: '#F9C6CB' }}
                // display='flex'
                // justify-content='center'
                justify='center'
                align='middle'>
                <Col>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'white', margin: '0px' }}>Elsa Jean </h3>
                </Col>
            </Row>
            <br />
            <br />
            <br />


            <h3 className='introSectionText' style={{ paddingRight: '0px', color: 'black', margin: '0px' }}>Terms & Conditions </h3>




            <br />
            <br />

            {/* <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}>1. </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}> Privacy Policy </h3>

                </Col>

            </Row> */}
            <br />
            <Row justify='center'>
                {/* <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px' }}> </h3>
                </Col> */}
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <p dir="ltr"><span>Terms &amp; Conditions</span><span><br /></span><span><br /></span><span>These Terms of Use incorporate our Privacy Policy (which tells you about how we use your personal information).</span></p>
                    <p dir="ltr"><span>Please read these Terms of Use carefully before you use our site. They are a legally binding agreement and contain important information on your legal rights and obligations.</span></p>
                    <p dir="ltr"><span>By accessing or using our site, you agree to comply with and be bound by these Terms of Use. If you do not agree to be bound by these Terms of Use, do not access or use our site.</span></p>
                    <p dir="ltr"><span>These Terms of Use apply to everyone who visits our site, whether or not you are a registered user.</span><span><br /><br /></span></p>
                    <p dir="ltr"><span>Accessing our site</span></p>
                    <p dir="ltr"><span>1.1 By accessing our site you confirm that:</span></p>
                    <p dir="ltr"><span>1.1.1 you accept and agree to comply with these Terms of Use;</span></p>
                    <p dir="ltr"><span>1.1.2 you have read and understood our Privacy Policy;</span></p>
                    <p dir="ltr"><span>1.1.3 you accept and agree to comply with our Cookies Policy; and</span></p>
                    <p dir="ltr"><span>1.1.4 you are over the age of 18 years old.</span><span><br /><br /></span></p>
                    <p dir="ltr"><span>1.2 Access to our site is permitted on a temporary basis and we reserve the right to withdraw or amend access to our site or any services we provide on our site without notice. As we do not charge you for accessing our site, we will not be liable to you if for any reason our site is unavailable at any time or for any period.</span><span><br /><br /></span></p>
                    <p dir="ltr"><span>1.3 Before you can access certain parts of our site, or our entire site, you may be required to complete a registration process by providing the information requested in a form. You must provide information which is complete, accurate, up-to-date and not misleading. We may rely on any information provided by you so it is important you comply with this requirement. You must tell us promptly about any changes to the information that you have provided to us.</span><span><br /><br /></span></p>
                    <p dir="ltr"><span>1.4 If you choose, or you are provided with a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of these Terms of Use.</span><span><br /><br /></span></p>
                    <p dir="ltr"><span>1.5 You are responsible for making all arrangements necessary for you to have access to our site. You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these Terms of Use, and that they comply with them.</span><span><br /><br /></span></p>
                    <p dir="ltr"><span>Ownership of OUR site AND Site Content</span></p>
                    <p dir="ltr"><span>2.1 We are the owner or the licensee of all Intellectual Property Rights in our site and in the material published on them (including the Site Content). The Intellectual Property Rights in our site and those materials are protected by laws and treaties around the world and all such rights are reserved by us.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>2.2 We grant you a revocable, non-exclusive, non-transferable, non-sub-licensable, limited right to access, retrieve and display our site and the Site Content on a computer screen, tablet or smartphone.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>2.3 Other than as set out in condition 2.2 above, you may not use, copy, reproduce, upload, post, modify, transmit, or mirror on another website or in any other media, distribute or create derivative works of our site or any Site Content. Other than as set out in condition 2.2 above, we do not grant you any rights or licenses to use our site or any Site Content.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>2.4 Our status (and that of any identified contributors) as the authors of material (including the Site Content) on our site must always be acknowledged.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>2.5 If you print off, copy or download any part of our site in breach of these Terms of Use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of any Site Content you have made.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>2.6 You acknowledge that the names, images and logos identifying &ldquo;Elsa Jean&rdquo;, our affiliated companies, or our licensors and their products and services are owned by us, our affiliated companies or our licensors. You may not use them without the prior written consent of us and our affiliated companies and/or our licensors (as applicable).</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Reliance on information posted</span></p>
                    <p dir="ltr"><span>3.1 Commentary and information posted on our site is not intended to amount to advice on which reliance should be placed. Subject to condition 4.1 , we shall not be liable for any loss or damage arising as a result of such reliance by you or any third party.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>3.2 We aim to update our site regularly, and may change the Site Content at any time. Any of the material (including the Site Content) on our site may be out of date at any given time, and we are under no obligation to update such material.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Our liability</span></p>
                    <p dir="ltr"><span>4.1 Nothing in these Terms of Use (including the Privacy Policy and Cookies Policy) excludes or limits our liability for:</span></p>
                    <p dir="ltr"><span>4.1.1 death or personal injury arising from our negligence;</span></p>
                    <p dir="ltr"><span>4.1.2 our fraud or fraudulent misrepresentation; or</span></p>
                    <p dir="ltr"><span>4.1.3 any other liability that cannot be excluded or limited by American Law.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>4.2 Subject to condition 4.1, you use our site at your own risk. Our site and the Site Content is provided without any guarantees, conditions or warranties of any kind. We do not warrant that the functions contained in our site will be uninterrupted or error-free, that defects will be corrected, or that the Site Content will be accurate, relevant or appropriate for your circumstances, purposes or requirements.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>4.3 Subject to condition 4.1 to the fullest extent permitted by law, we expressly exclude:</span></p>
                    <p dir="ltr"><span>4.3.1 all conditions, warranties, representations and other terms which might otherwise apply to your use of our site and/or any Site Content, whether express or implied by statute, the common law or the law of equity;</span></p>
                    <p dir="ltr"><span>4.3.2 any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with our site or in connection with the use, inability to use, or results of the use, of our site, the use of or reliance on any Site Content, or use of any website linked to it, howsoever arising and whether caused by breach of contract, tort (including negligence) or otherwise.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>4.4 Whilst we will use reasonable endeavors to ensure Site Content and any software and/or data made available on or through our site does not contain any viruses or harmful code, you understand and agree that any Site Content, software and/or data downloaded or otherwise obtained through the use of our site is downloaded and used at your own discretion and risk. Subject to condition 4.1, you acknowledge and agree that you will be solely responsible for all loss and damage, including without limitation damage to your own computer system and loss of data arising in connection with the download of such Site Content, software and/or data.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>4.5 Where our site contains links to other websites and resources provided outside the https://elsajeannft.io/ domain, such links are provided for your information only. Such links are not and should not be interpreted as endorsement by us of those linked websites. We are not responsible for the privacy practices or content of any such linked website.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>4.6 Reference to third party products, services, companies and websites on our site is for information purposes only and constitutes neither an endorsement nor a recommendation, unless specifically stated.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Uploading user content to our site</span></p>
                    <p dir="ltr"><span>5.1 Whenever you make use of a feature that allows you to upload User Content to our site, or to make contact with other users of our site, you must comply with the Content Standards. All User Content you upload or post must comply with the Content Standards. You agree to indemnify us and keep indemnified on demand against all loss and damage suffered by, incurred by or awarded against us arising out of or in relation to User Content which does not comply with the Content Standards.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>5.2 We will treat all User Content you post and upload to our site as non-confidential and non-proprietary. You grant us a perpetual, royalty free, non-exclusive license (including the right to grant sub-licences) to use, copy, distribute, reproduce and publish any and all User Content (including, without limitation, on our site, on other website, on physical products and in promotional and/or marketing material developed, in each case whether developed by us or on our behalf or on behalf of our affiliates).</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>5.3 We may disclose your identity to any third party who claims that any User Content posted or uploaded by you defames them, constitutes a breach of their Intellectual Property Rights or breaches their right to privacy.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>5.4 We have the right to remove any User Content or posting you make on our site if, in our opinion, such material does not comply with the Content Standards.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>5.5 You shall only upload and post User Content that complies with our Content Standards and that you either own or have permissions to upload or post.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>5.6 You represent and warrant on an ongoing basis that you:</span></p>
                    <p dir="ltr"><span>5.6.1 are the owner or authorized licensee of all User Content;</span></p>
                    <p dir="ltr"><span>5.6.2 have all necessary rights (including, but not limited to, all Intellectual Property Rights) and consents required to publish the User Content and to grant us the rights in the User Content set out in these Terms of Use;</span></p>
                    <p dir="ltr"><span>5.6.3 will not upload or post User Content that violates applicable law or regulations; and</span></p>
                    <p dir="ltr"><span>5.6.4 have all required permissions and consents from any third party whose personal information is included in any User Content.</span></p>
                    <p dir="ltr"><span>5.7 You agree to make your User Content available to us in the manner envisaged by these Terms of Use without payment or other compensation to you. We may, at our sole discretion, access, delete, edit or remove any User Content registered on our site, without permission of or notification to you. We may (but are not obliged to) monitor your activities (or be alerted to the same) on our site for any reason.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Privacy policy</span></p>
                    <p dir="ltr"><span>6.1 We process personal information about you in accordance with our Privacy Policy. By using our site, you consent to such processing and you warrant that all personal information you provide is complete, accurate, up-to-date and not misleading.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Cookies policy</span></p>
                    <p dir="ltr"><span>7.1 We use cookies in accordance with our Cookies Policy. By using our site, you agree to our use of cookies in accordance with our Cookies Policy.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Viruses, hacking and other offenses</span></p>
                    <p dir="ltr"><span>8.1 You must not misuse our site by knowingly introducing viruses or harmful code. You must not attempt to gain unauthorized access to our site, the server on which our site are stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>8.2 By breaching this condition 8, you would commit a criminal offense under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Prohibited uses</span></p>
                    <p dir="ltr"><span>9.1 You may use our site only for lawful purposes. You may not use our site:</span></p>
                    <p dir="ltr"><span>9.1.1 in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</span></p>
                    <p dir="ltr"><span>9.1.2 for the purpose of harming or attempting to harm minors in any way;</span></p>
                    <p dir="ltr"><span>9.1.3 to send, upload, download, use or re-use any material which does not comply with our Content Standards;</span></p>
                    <p dir="ltr"><span>9.1.4 to send, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (i.e. spam);</span></p>
                    <p dir="ltr"><span>9.1.5 for any purpose that in our reasonable opinion damages our reputation or the reputation of our affiliated companies;</span></p>
                    <p dir="ltr"><span>9.1.6 for any purpose other than your personal, non-business use.</span></p>
                    <p dir="ltr"><span>Interactive services</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>10.1 We may from time to time provide interactive services on our site, including, without limitation:</span></p>
                    <p dir="ltr"><span>10.1.1 chat rooms;</span></p>
                    <p dir="ltr"><span>10.1.2 bulletin boards;</span></p>
                    <p dir="ltr"><span>10.1.3 comments features,</span></p>
                    <p dir="ltr"><span>(together the interactive services and each an interactive service).</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>10.2 Where we do provide any interactive service, we will provide clear information to you about the kind of service offered, if it is moderated or not and, if so, what form of moderation is used (including whether it is human or technical moderation).</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>10.3 We are under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and subject to condition 4.1 we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our Content Standards, whether the service is moderated or not.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>10.4 If you believe that any Site Content or User Content appearing on our site does not comply with our Content Standards then you may notify us and request that such content is removed from our site by emailing </span><span>whitelist@elsajeannft.io</span><span> where we are notified of content that does not comply with our Content Standards, we shall remove the relevant content as soon as reasonably practicable following receipt of such notification.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Content standards</span></p>
                    <p dir="ltr"><span>11.1 These Content Standards apply to any and all User Content which you contribute to our site and to any interactive services associated with it.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>11.2 You must comply with the spirit of the Content Standards as well as the letter. The Content Standards apply to each part of any User Content as well as to its whole.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>11.3 All User Content must:</span></p>
                    <p dir="ltr"><span>11.3.1 be accurate (where it states facts);</span></p>
                    <p dir="ltr"><span>11.3.2 be genuinely held (where it states opinions); and</span></p>
                    <p dir="ltr"><span>11.3.3 comply with applicable law in the USA and in any country from which it is posted or uploaded.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>11.4 User Content must not:</span></p>
                    <p dir="ltr"><span>11.4.1 contain any material which is defamatory;</span></p>
                    <p dir="ltr"><span>11.4.2 contain any material which is obscene, offensive, hateful or inflammatory;</span></p>
                    <p dir="ltr"><span>11.4.3 contain s*xually explicit material;</span></p>
                    <p dir="ltr"><span>11.4.4 promote violence;</span></p>
                    <p dir="ltr"><span>11.4.5 promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</span></p>
                    <p dir="ltr"><span>11.4.6 infringe any third party Intellectual Property Rights;</span></p>
                    <p dir="ltr"><span>11.4.7 be likely to and/or intended to deceive any person;</span></p>
                    <p dir="ltr"><span>11.4.8 be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</span></p>
                    <p dir="ltr"><span>11.4.9 promote any illegal activity;</span></p>
                    <p dir="ltr"><span>11.4.10 be threatening, abusive, invade another&rsquo;s privacy, or cause annoyance, inconvenience or anxiety to any third party;</span></p>
                    <p dir="ltr"><span>11.4.11 be likely to and/or intended to harass, upset, embarrass, alarm or annoy any other person;</span></p>
                    <p dir="ltr"><span>11.4.12 be used to impersonate any person, or to misrepresent your identity or affiliation with any person;</span></p>
                    <p dir="ltr"><span>11.4.13 give the impression that it emanates from us, if this is not the case; or</span></p>
                    <p dir="ltr"><span>11.4.14 advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Suspension and termination</span></p>
                    <p dir="ltr"><span>12.1 Failure to comply with our Content Standards constitutes a material breach of these Terms of Use and may result in our taking action against you, including without limitation all or any of the following actions:</span></p>
                    <p dir="ltr"><span>12.1.1 immediate, temporary or permanent withdrawal of your right to use our site;</span></p>
                    <p dir="ltr"><span>12.1.2 immediate, temporary or permanent removal of any User Content uploaded by you to our site;</span></p>
                    <p dir="ltr"><span>12.1.3 issue of a warning to you;</span></p>
                    <p dir="ltr"><span>12.1.4 bringing legal proceedings against you to recover our losses and damages arising out of or in connection with your breach, including pursuant to the indemnity you give us in condition 5.1 above;</span></p>
                    <p dir="ltr"><span>12.1.5 further legal action against you; and/or</span></p>
                    <p dir="ltr"><span>12.1.6 disclosure of information by us in respect of the breach to law enforcement authorities as we reasonably believe is necessary.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Linking to our site</span></p>
                    <p dir="ltr"><span>13.1 You may link to our home pages, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>13.2 You must not establish a link from any website that is not owned by you.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>13.3 Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page. We reserve the right to withdraw linking permission without notice. The website from which you are linking must comply in all respects with our Content Standards.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>CHANGES TO OUR TERMS OF USE</span></p>
                    <p dir="ltr"><span>We may change and update these Terms of Use at any time. Please periodically review these Terms of Use as your continued use of our site indicates your agreement to any changes that we make. Some of the provisions contained in these Terms of Use may also be superseded by provisions or notices published elsewhere on our site.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Competitions and promotions</span></p>
                    <p dir="ltr"><span>16.1 We may from time to time run competitions and/or promotions from our site, which shall be governed by separate terms and conditions provided to you by us.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>GENERAL</span></p>
                    <p dir="ltr"><span>17.1 We may transfer our rights and obligations under these Terms of Use between you and us to another organization, but this will not affect your or our rights or obligations under these Terms of Use. We will notify you by posting on our site if this happens. You may not transfer your rights or obligations under these Terms of Use to another person without our prior written consent.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>17.2 These Terms of Use are between you and us. No other person shall have any rights to enforce any of these Terms of Use.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>17.3 Headings in these Terms of Use are for convenience only and will have no legal meaning or effect.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>17.4 Each of the conditions and sub-conditions of these Terms of Use operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining conditions and/or sub-conditions (as applicable) will remain in full force and effect.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>17.5 If we fail to insist that you perform any of your obligations under these Terms of Use, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>17.6 Entire Agreement:</span></p>
                    <p dir="ltr"><span>17.6.1 These Terms of Use (including our Privacy Policy and Cookies Policy) set out the entire agreement and understanding between the parties, and supersede all proposals and prior agreements, arrangements and understandings between the parties, relating to their subject matter.</span></p>
                    <p dir="ltr"><span>17.6.2 Subject to condition 17.6.3 , each party acknowledges that in entering into these Terms of Use it has not relied on any representation, warranty, collateral contract or other assurance (except those set out in these Terms of Use) made by or on behalf of any other party at any time. Each party waives all rights and remedies which, but for this condition 17.6.2 , might otherwise be available to it in respect of any such representation, warranty, collateral contract or other assurance.</span></p>
                    <p dir="ltr"><span>17.6.3 Nothing in this condition 17.6 limits or excludes any liability for fraud or fraudulent misrepresentation.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>Definitions</span></p>
                    <p dir="ltr"><span>Where used in these Terms of Use, the following capitalized terms shall have the following meanings:</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>18.1 Content means all information, profiles, reports, materials, feedback, data, submissions, ideas, tags, messages, recommendations, text, material, articles, photos, music, videos, posts, comments, responses, e-mail and other content.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>18.2 Content Standards means those standards set out in condition 9 (Prohibited Use) and condition 11 (Content Standards), which you must comply with when posting and uploading User Content.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>18.3 Intellectual Property Rights means:</span></p>
                    <p dir="ltr"><span>(a) all (i) patents, patent applications and related patent rights (including divisions, continuations, continuations in-part, renewals, reissues, and extensions thereof) and other rights in inventions; (ii) rights associated with works of authorship including moral rights, copyrights and registration thereof; (iii) rights relating to the protection of trade secrets and confidential information; (iv) rights relating to the protection of know-how; (v) rights relating to the protection of trademarks, service marks, trade names business names and logos (including rights in goodwill attached thereto); (vi) rights in internet domain names and website addresses; (vii) database rights; (vii) rights in registered and unregistered designs; (viii) semi-conductor topography rights; (ix) other intellectual property rights; and (x) all priority rights related to the foregoing under applicable conventions, in each case subsisting at any time (whether registered or unregistered);</span></p>
                    <p dir="ltr"><span>(b) any pending applications or rights to apply for registrations of any of the rights set out in limb (a) that are capable of registration in any country or jurisdiction; and</span></p>
                    <p dir="ltr"><span>(c) any similar or analogous rights to any of the rights set out in limb (a) in any jurisdiction.</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>18.4 Site Content means Content that we have uploaded to and posted on our site (including Content provided by other users).</span></p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr"><span>18.5 User Content means all Content posted or uploaded onto our site by you or anyone on your behalf, including, but not limited to, any personal information about you provided by you or collected by us via our site or otherwise.</span></p>
                </Col>

            </Row>




            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <WhyCardanoBottom state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />


        </div>
    )
}

export default TermsConditions