import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';



const WhyCardanoSection2 = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const why2 = useRef();

    useEffect(() => {
        if (why2 !== state?.refs?.why2) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'why2': why2 } })
        }
    }, [state])


    return (
        <div ref={why2} className='why2' style={{
            // height: '80vh',
        }}>
            <Row justify='center' className='why2SubDiv' style={{ height: '100%' }}>
                <Col xs={20} sm={18} md={16} lg={16} xl={16} style={{ height: '100%' }}>
                    <Row justify='center' align='middle' style={{ height: '100%', paddingTop: '100px', paddingBottom: '150px' }}>
                        <Col >
                            <Row justify='center'>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>02 </h3>
                            </Row>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>Scalable</h3>
                                {/* <h3 className='whyh2' style={{ paddingRight: '20px' }}>Scalability</h3> */}
                            </Row>
                            <Row justify='center' style={{paddingBottom: '40px'}}>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Cardano </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> can </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> handle  </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> a large </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> number  </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> of </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> transactions.  </h1>
                           
                                {/* <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>A great USP </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>of Cardano </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>is the </h1>
                                <h1 className='whyh3' style={{ color: '#FFFA82', paddingRight: '20px', textAlign: 'left' }}>scalability </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>so that  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>the blockchain </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>can handle </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>a large number  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>of transactions.  </h1> */}
                            </Row>
                            <Row justify='center'>
                             
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>You </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>can  </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> buy </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> my  </h1>
                               <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> NFTs  </h1>
                               <h1 className='whyh3' style={{   color: '#FFFA82', paddingRight: '20px', textAlign: 'left' }}> within  </h1>
                               <h1 className='whyh3' style={{  color: '#FFFA82',  paddingRight: '20px', textAlign: 'left' }}>minutes! </h1>
                              
                            </Row>
                            {/* <Row justify='center'>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> With </h1>
                                <h1 className='whyh3' style={{ color: '#58D7FF', paddingRight: '20px', textAlign: 'left'  }}>Hydra</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>  as a </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> layer-2 system, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> Cardano aims </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>  to improve </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> network scalability </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>  and off-chain </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> transaction </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> processing. </h1>
                            </Row> */}

                        </Col>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoSection2


