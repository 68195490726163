import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom"
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';
import { motion } from 'framer-motion'
import VideoJS from '../functions/VideoJS';
import ElsaExplainVideo from '../images/ElsaExplainVideo.mp4'
import ElsaOpeningNami from '../images/ElsaOpeningNami.mp4'
import Artboard from '../images/Artboard 1.png'


const FormSection = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(null);


    const [formState, setFormState] = useState({ name: '', email: '', wallet: '' });

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);
    useEffect(() => {
        if (screenSize > 1368) {
            setActiveMenu(false);
        }
        // else if (screenSize > 1000) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 700) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 500) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 300) {
        //     setActiveMenu(true);
        // }
        // else {
        //     setActiveMenu(true);
        // }
    }, [screenSize]);


    const formSection = useRef(null);

    useEffect(() => {
        if (formSection !== state.refs.formSection) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'formSection': formSection } })
        }
    }, [state])



    const container = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 2.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -100,
            // y: 100,
            rotate: 2,
            scale: 0,
            transition: {
                delay: 1,
                type: "spring",
                duration: 5.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                type: "spring",
                duration: 0.5,
                bounce: 0.1,

            }
        }
    }



    // const history = useHistory();


    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }



    async function handleSubmit(event) {
        console.log('event', event)
        event.preventDefault();
        const form = event.target;
        await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                'form-name': form.getAttribute('name'),
                // "form-name": "whitelist",
                ...formState,
            }),
        })
            // return response.json()
            .then((response) => {
                // navigate("/thank-you/")

                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                console.log('response', response)
                alert('You are on the whitelist! Thank you for signing up!')
            })
            .catch((error) => alert(error));
    };

    function handleChange(e) {
        console.log('e', e)
        setFormState((prev) => prev = { ...formState, [e.target.name]: e.target.value.toString() });
    }





    const videoTwo = useRef();

    useEffect(() => {
        if (videoTwo !== state.refs.videoTwo) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'videoTwo': videoTwo } })
        }
    }, [state])



    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: false,
        muted: true,
        controls: true,
        responsive: true,
        fluid: true,
        // height: '100%',
        sources: [{
            // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
            src: "https://player.vimeo.com/video/764874646?h=e79332f1ee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };


    // useEffect(() => {
    //     setTimeout(() => {
    //         var iFrame = document.getElementById('iFrame1');
    //         resizeIFrameToFitContent(iFrame);
    //     }, 2000);
    // }, []);

    // function resizeIFrameToFitContent(iFrame) {

    //     iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
    //     iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    //     iFrame.contentWindow.document.body.video.style.objectFit = 'cover';
    // }

    // window.addEventListener('DOMContentLoaded', function (e) {

    //     var iFrame = document.getElementById('iFrame1');
    //     resizeIFrameToFitContent(iFrame);

    //     // // or, to resize all iframes:
    //     // var iframes = document.querySelectorAll("iframe");
    //     // for( var i = 0; i < iframes.length; i++) {
    //     //     resizeIFrameToFitContent( iframes[i] );
    //     // }
    // });

    // var containerr = document.getElementById('embed_container');
    // var video = document.getElementById('iFrame1');
    // var ratio = 9 / 16; //this is why the 56.25% padding hack exists

    // function resizer() {
    //     var width = parseInt(window.getComputedStyle(containerr)['width'], 10);
    //     var height = (width * ratio);

    //     video.style.width = width + 'px';
    //     video.style.height = height + 'px';
    //     video.style.marginTop = '-3.278%'; //~732px wide, the video border is about 24px thick (24/732)

    //     containerr.style.height = (height * 0.88) + 'px'; //0.88 was the magic number that you needed to shrink the height of the outer container with.
    // }
    // //attach event on resize
    // window.addEventListener('resize', resizer, false);

    // //call function for initial sizing
    // //no need for padding hack since we are setting the height based off of the width * aspect ratio
    // resizer();
    // //container.style.padding = 0;

    return (
        <div ref={formSection} className='formSectionTwoMainDiv'
        // style={{ height: '50vh' }}
        >
            <div className='introSectionTwoSubOne' style={{ height: '100%' }}>
                <div className='introSectionTwoSubTwo' style={{ height: '100%' }} >

                    <Row justify='center' align='middle' style={{ height: '100%', paddingTop: '100px', paddingBottom: '100px' }} >
                        <Col xs={22} sm={22} md={22} lg={20} xl={20}>

                            {/* <motion.h3 className='introSectionText' style={{ paddingRight: '0px' }} variants={item}>Whitelist  </motion.h3> */}
                            <Row justify='center' align='top' gutter={[40, 40]} style={{ height: '100%' }} >
                                {/* <Col xs={22} sm={22} md={12} lg={12} xl={12}>
                                    <div className='formContainer' align='left' style={{ borderRadius: '40px', backgroundColor: 'white', padding: '40px', width: '100%' }}>
                                        <form
                                            data-netlify="true"
                                            data-netlify-honeypot="bot-field"
                                            // netlify
                                            name="whitelist"
                                            method="post"
                                            onSubmit={handleSubmit}
                                        // onSubmit='submit'
                                        >
                                            <input type="hidden" name="form-name" value="whitelist" />
                                            <label className='formLabelText'>Your Name:
                                                <p className='formLabelText'>
                                                    <input type="text" name="name" required style={{ width: '100%', borderRadius: '40px' }} onChange={(e) => handleChange(e)} className='formInput' />
                                                </p>
                                            </label>
                                            <label className='formLabelText'>Your Email:
                                                <p className='formLabelText'>
                                                    <input className='formInput' type="email" name="email" required style={{ width: '100%', borderRadius: '40px' }} onChange={(e) => handleChange(e)} />
                                                </p>
                                            </label>
                                            <label className='formLabelText'>Cardano wallet address:
                                                <p className='formLabelText'>
                                                    <input name="wallet" required style={{ width: '100%', borderRadius: '40px' }} onChange={(e) => handleChange(e)} className='formInput'></input>
                                                </p>
                                            </label>
                                            <br />
                                            <p>
                                                <button className='buyNFTButton' type="submit" style={{ width: '100%' }}>Get on whitelist</button>
                                            </p>
                                        </form>

                                    </div>


                                                                        
                                    <div align='left' style={{ borderRadius: '40px', backgroundColor: 'white', padding: '40px', width: '100%' }}>
                                        <h3> name</h3>
                                        <input />
                                        <br />
                                        <h3> email</h3>
                                        <input />
                                        <br />
                                        <h3> Cardano wallet address</h3>
                                        <input />
                                        <br />
                                        <button>Get on whitelist</button>

                                    </div>
                                </Col> */}




                                <Col xs={22} sm={22} md={12} lg={12} xl={12}>
                                    <motion.div
                                        variants={container}
                                        initial={'hidden'}
                                        whileInView={'show'}
                                        exit={{ opacity: 0, y: -200 }}
                                        viewport={{ once: false, amount: 0 }}
                                    >

                                        <Row justify='center'>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>Don't  </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>you  </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>have  </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>a  </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>wallet </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>yet ?  </motion.h3>
                                            {/* <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#00A3FF' }} variants={item}>Elsa community… </motion.h3> */}
                                            {/* <h3 className='introSectionText' style={{paddingRight: '20px'}}>And</h3> */}
                                            {/* <h3 className='introSectionText' style={{paddingRight: '20px', color: '#F9C6CB'}}>me. </h3> */}
                                            {/* <h3 className='introSectionText' > And our dreams.</h3> */}

                                        </Row>
                                        <Row justify='center'>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>Here </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>is  </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>me  </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>opening   </motion.h3>
                                            <motion.h3 className='formSectionText' style={{ paddingRight: '20px' }} variants={item}>one : </motion.h3>
                                            <Col span={24} type="flex" align="middle">
                                                <br />

{/* 
                                                <div
                                                    id='embed_container'
                                                    className='vimeoNamiDiv'
                                                    display='flex'
                                                    justify-content='center'
                                                    style={{
                                                        // padding: '0 0 0 0', 
                                                        // width: '100%',
                                                        // height: '100%',
                                                        // height: 'auto',
                                                        objectFit: 'cover',
                                                        padding: '56.25% 0 0 0',
                                                        // overflow: 'hidden',
                                                        borderRadius: '40px',
                                                        position: 'relative',
                                                        margin: '30px',
                                                        backgroundColor: 'white',
                                                        zIndex: 10,
                                                    }}
                                                //  style={"padding:56.25% 0 0 0;position:relative;"}
                                                >
                                                    <iframe
                                                        id='iFrame1'
                                                        src={ElsaOpeningNami}
                                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullscreen
                                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                                        // onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: '40px',
                                                            objectFit: 'cover',
                                                            margin: 0,
                                                            padding: 0,
                                                            zIndex: 9,
                                                        }}
                                                        title="ElsaOpeningNami"
                                                        marginwidth="0"
                                                        marginheight="0"
                                                        hspace="0"
                                                        vspace="0"
                                                        scrolling="no"
                                                    >

                                                    </iframe>
                                                </div> */}


                                                <div display='flex'
                                                    justify-content='center'
                                                    style={{
                                                        padding: '56.25% 0 0 0',
                                                        overflow: 'hidden',
                                                        // borderRadius: '20px',
                                                        // padding: '56.25% 0 0 0',
                                                        // padding: '0 0 0 0', 
                                                        position: 'relative',
                                                        margin: '40px'
                                                    }}
                                                //  style={"padding:56.25% 0 0 0;position:relative;"}
                                                >
                                                    <iframe src="https://player.vimeo.com/video/764900812?h=1ff3bee983&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%', height: '100%',
                                                            // flexGrow: 1,
                                                            // borderRadius: '40px',
                                                            // objectFit: 'cover'
                                                        }}
                                                        title="ElsaOpeningNami">
                                                    </iframe>
                                                </div>
                                                {/* <script src="https://player.vimeo.com/api/player.js"></script> */}

                                                {/* <div ref={videoTwo} className='videoOneSection' style={{
                                                // height: '80vh',
                                                width: '80%',
                                                // margin: '40px'
                                            }}>

                                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                            </div> */}


                                                <br />
                                                <div>
                                                    <Row justify='center' align='middle'>

                                                        <a
                                                            className='formSectionText'
                                                            href="https://namiwallet.io/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        // style={{color: '#61dafb'}}
                                                        >
                                                            <img src={Artboard} alt='nami-logo' style={{ width: '1em', paddingRight: '10px' }} />

                                                            Nami
                                                        </a>
                                                    </Row>
                                                </div>

                                                {/* <Row justify='center'>

                                                    <a
                                                        className='formSectionText'
                                                        href="https://namiwallet.io/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    // style={{color: '#61dafb'}}
                                                    >- Nami</a>
                                                </Row>
                                                <Row justify='center'>

                                                    <a
                                                        className='formSectionText'
                                                        href="https://flint-wallet.com/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    // style={{color: '#61dafb'}}

                                                    >- Flint</a>
                                                </Row>
                                                <Row justify='center'>

                                                    <a
                                                        className='formSectionText'
                                                        href="https://eternl.io/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    // style={{color: '#61dafb'}}

                                                    >- Eternl</a>
                                                </Row>

                                                <Row justify='center'>

                                                    <a
                                                        className='formSectionText'
                                                        href="https://gerowallet.io/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    // style={{color: '#61dafb'}}

                                                    >- Gero</a>
                                                </Row> */}

                                            </Col>
                                            {/* <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#00A3FF' }} variants={item}>Elsa community… </motion.h3> */}
                                            {/* <h3 className='introSectionText' style={{paddingRight: '20px'}}>And</h3> */}
                                            {/* <h3 className='introSectionText' style={{paddingRight: '20px', color: '#F9C6CB'}}>me. </h3> */}
                                            {/* <h3 className='introSectionText' > And our dreams.</h3> */}

                                        </Row>
                                    </motion.div>
                                </Col>
                            </Row>

                            {/* <br />
                            <br />
                            <br />
                            <br />
                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                            >

                                <Row justify='center'>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px' }} variants={item}>Come in  </motion.h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px' }} variants={item}> and enjoy the </motion.h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#00A3FF' }} variants={item}>Elsa community… </motion.h3>
                                    <h3 className='introSectionText' style={{paddingRight: '20px'}}>And</h3>
                                    <h3 className='introSectionText' style={{paddingRight: '20px', color: '#F9C6CB'}}>me. </h3>
                                    <h3 className='introSectionText' > And our dreams.</h3>

                                </Row>
                            </motion.div>
                            <br />
                            <h3 className='introSectionText'>Come in and enjoy the Elsa community…</h3>
                            <br />
                            <h3 className='introSectionText'>My Elsa Jean Community Pass will give you private access to my little secrets.</h3>
                            <br />
                            <br />
                            <br />
                            <br /> */}
                        </Col>
                    </Row>
                </div>
            </div >

        </div >
    )
}

export default FormSection