import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';



const WhyCardanoSection5 = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const why5 = useRef();

    useEffect(() => {
        if (why5 !== state?.refs?.why5) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'why5': why5 } })
        }
    }, [state])


    return (
        <div ref={why5} className='why5' style={{
            // height: '80vh',
        }}>
            <Row justify='center' style={{ height: '100%', paddingBottom: '250px', paddingTop: '250px' }}>
                <Col xs={20} sm={18} md={16} lg={16} xl={16} style={{ height: '100%' }}>
                    <Row justify='center' align='middle' style={{ height: '100%'}}>
                        <Col >
                            <Row justify='center'>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>05 </h3>
                            </Row>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}> Working together </h3>
                                {/* <h3 className='whyh2' style={{ paddingRight: '20px' }}>Wide range of commitments to decentralization</h3> */}
                            </Row>
                            <Row justify='center' style={{paddingBottom: '40px'}}>
                            {/* 2021 saw Cardano making a significant step toward decentralization.  */}
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Cardano  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  is  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> built </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> in </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  a  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  way  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> that  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  allows  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> different  </h1>
                                 <h1 className='whyh3' style={{  paddingRight: '20px', textAlign: 'left' }}>  blockchains  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  to  </h1>
                                 <h1 className='whyh3' style={{ color: '#67FFD4',  paddingRight: '20px', textAlign: 'left' }}>  interact  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> with  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  each  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  other. </h1>
                                {/* <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>2021 </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> saw Cardano </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>making a</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>significant</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>step toward</h1>
                                <h1 className='whyh3' style={{ color: '#67FFD4', paddingRight: '20px', textAlign: 'left' }}>decentralization. </h1> */}
                                {/* <h1 className='whyh3'>ON CARDANO</h1> */}
                            </Row>
                           
                           
                           
                           
                            {/* <Row justify='center'>
                                The IOHK team announced that they handed over power to its community responsible for producing blocks on Cardano and maintaining governance within the system architecture.
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>The IOHK team</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>announced that </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>they handed over </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>power to</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> its </h1>
                                <h1 className='whyh3' style={{ color: '#FFCBD1', paddingRight: '20px', textAlign: 'left'  }}>community</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> responsible for </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>  producing blocks</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> on Cardano</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> and maintaining</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> governance</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> within</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> the system </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> architecture. </h1>
                                <h1 className='whyh3'>ON CARDANO</h1>
                            </Row> */}

                        </Col>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoSection5


