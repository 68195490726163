import { DownOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import VideoJS from '../functions/VideoJS';
import DescriptionComponent from './DescriptionComponent';
const { Paragraph, Text } = Typography;

// this is the tutorial component
// it contains a video, a title and a description
// is is used on the tutorials page 
// google sheets data . map ( tutorial )
const TutorialComponent = ({ tutorial }) => {
    const [ellipsis, setEllipsis] = useState(true);

    const [videoUrl, setVideoUrl ] = useState('')
   
    useEffect(() => {
      console.log('drive url:', tutorial?.url)
      // check the tutorial video URL
      // we need to transform it from a share link to a download link
      if (tutorial?.url.includes('/file/d/')) {
        setVideoUrl("https://drive.google.com/uc?export=download&id="+tutorial?.url.slice(tutorial?.url.lastIndexOf('/file/d/') + 8, tutorial?.url.indexOf('/view')))
      }
      console.log('drive url includes:', tutorial?.url.includes('/file/d/'))
      console.log('drive url:', tutorial?.url.slice(tutorial?.url.lastIndexOf('/file/d/') + 8, tutorial?.url.indexOf('/view')))
    
      
    }, [tutorial])
    

    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: false,
        muted: false,
        controls: true,
        responsive: true,
        fluid: true,
        // height: '100%',
        style: { borderRadius: '20px', width: '100%', height: "50%" },
        // borderRadius: '40px',
        sources: [{
            // 1A6jpd2FW531PawLP3GAUj9pVJd1ZIBG0
            // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
            src: "https://drive.google.com/uc?export=download&id="+tutorial?.url.slice(tutorial?.url.lastIndexOf('/file/d/') + 8, tutorial?.url.indexOf('/view')),
            // src: 'https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi',
            // src: 'https://drive.google.com/uc?export=download&id=1A6jpd2FW531PawLP3GAUj9pVJd1ZIBG0',
            // src: Rick,
            // src: ElsaExplainVideo,
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            console.log('player is waiting');
            // videojs.log('player is waiting');
        });
       

        player.on('dispose', () => {
            console.log('player will dispose');
            // videojs.log('player will dispose');
        });
    };


    useEffect(() => {
      console.log('this is tutorial component on mount')
    
    }, [])
    
    return (
        <Row justify='center' style={{ paddingBottom: '100px' }}>
            <Col span={24} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '30px', overflow: 'hidden' }}>
                {/* <Video /> */}
                <div style={{ borderRadius: '20px', overflow: 'hidden' }}>

                    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                </div>
                <br />
                <h5 style={{ fontSize: '32px' }}>{tutorial?.title}</h5>
                <br />
                <DescriptionComponent tutorial={tutorial} />

                {/* <Row justify="start">
                    <Paragraph
                        ellipsis={
                            ellipsis
                                ? {
                                    rows: 2,
                                    // expandable: true,
                                    // symbol: 'more',
                                }
                                : false
                        }
                    >
                        <h6 style={{ fontSize: '20px'}}>{tutorial?.description}</h6>

                    </Paragraph>
                </Row>
                <Row justify="center">
                    <DownOutlined
                        style={{ fontSize: "24px" }}
                        onClick={() => {
                            setEllipsis(!ellipsis);
                        }} />
                </Row> */}
            </Col >
        </Row >
    )
}

export default TutorialComponent