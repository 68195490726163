import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import HeaderSection from '../components/Header'
import TutorialComponent from '../tutorialsPageComponents/TutorialComponent'
import { Link, useRouteMatch } from 'react-router-dom';

import { useGetTutorialsListFromSheetQuery } from '../services/driveApi';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Gradient01 from '../images/lockedGradient.png';

import tutorialPageStyles from '../tutorialsPageComponents/tutorialPage.module.css';

const tutorials = [
    {
        url: "https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi",
        title: "Setup Cardano Wallet",
        description: "this is a first tutorial. Enjoy and learn! There saidioanfoiandfa fdaidsofoidsfh piasfjoisa fahs ioshfoia dsfhioashf as ihaiosf oiasyrhiwq pdkSDU AWRAFU90 u9W ADIF HOISOD"
    },
    {
        url: "https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi",
        title: "How to buy NFT?",
        description: "this is a first tutorial. Enjoy and learn! rial. Enjoy and learn! There saidioanfoiandfa fdaidsofoidsfh piasfjoisa fahs ioshfoia dsfhioashf as ihaiosf oiasyrhiwq pdkSDU AWRAFU90 u9W"
    },
    {
        url: "https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi",
        title: "How to sell NFT?",
        description: "this is a first tutorial. Enjoy and learn! rial. Enjoy and learn! There saidioanfoiandfa fdaidsofoidsfh piasfjoisa fahs ioshfoia dsfhioashf as ihaiosf oiasyrhiwq pdkSDU AWRAFU90 u9W"
    },
    {
        url: "https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi",
        title: "The Cardano ecosystem",
        description: "this is a first tutorial. Enjoy and learn! rial. Enjoy and learn! There saidioanfoiandfa fdaidsofoidsfh piasfjoisa fahs ioshfoia dsfhioashf as ihaiosf oiasyrhiwq pdkSDU AWRAFU90 u9W"
    },
]

const TutorialsPage = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const [skip1, setSkip1] = useState(true);
    const [tutorialsArray, setTutorialsArray] = useState([]);
    const { data: allTutorials } = useGetTutorialsListFromSheetQuery(tutorials, { skip: skip1 });

    useEffect(() => {
        if (skip1) {
            setSkip1(false)
        }

    }, [])

    useEffect(() => {
        if (!skip1) {
            console.log('allTutorials', allTutorials)
            setSkip1(true)
        }

    }, [allTutorials])


    return (
        <div style={{ height: '100%' }}>
            <Col span={24} style={{ height: '100%' }}>
                <img src={Gradient01} alt='' className={tutorialPageStyles.tutorialPageBackground} style={{height: '100%', width: '100%', minHeight: '-webkit-fill-available', position: 'absolute', top: 0, left: 0, maxWidth: '-webkit-fill-available' }} />
                {/* <Col span={24} style={{ backgroundColor: "#B0BDFF" }}> */}
                {/* <Col span={24} style={{ backgroundColor: "#e6e6fa" }}> */}
                <HeaderSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} style={{ paddingBottom: '150px' }} />
                <Row justify='center' style={{ paddingTop: '150px', paddingBottom: '15px' }}>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Link to='/' style={{ height: '100%', alignContent: 'middle' }}>
                            <ArrowLeftOutlined style={{ fontSize: '40px' }} />
                        </Link>
                    </Col>
                    <Col xs={20} sm={20} md={16} lg={14} xl={12}>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    </Col>
                </Row>
                <Row justify='center' style={{ paddingBottom: '150px' }}>
                    <Col xs={22} sm={20} md={16} lg={14} xl={12}>


                        {allTutorials?.map((tutorial, i) => {
                            return <TutorialComponent key={i} tutorial={tutorial} />
                        })}

                    </Col>
                </Row>
            </Col>
        </div >


    )
}

export default TutorialsPage