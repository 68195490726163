import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';

const BuyNFTSectionOne = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(null);


    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);
    useEffect(() => {
        if (screenSize > 1368) {
            setActiveMenu(false);
        }
        else if (screenSize > 1000) {
            setActiveMenu(true);
        }
        // else if (screenSize > 700) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 500) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 300) {
        //     setActiveMenu(true);
        // }
        // else {
        //     setActiveMenu(true);
        // }
    }, [screenSize]);


    const buyNFT = useRef(null);

    useEffect(() => {
        if (buyNFT !== state.refs.buyNFT) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'buyNFT': buyNFT } })
        }
    }, [state])

    const scrollToSection = (elementRef) => {
        // console.log('elementRef', elementRef)
        if (elementRef?.current) {

            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: "smooth",
            })
        }
    }


    return (
        <div ref={buyNFT} className='buyNFTOneSectionMainDiv' >
            <div className='buyNFTOneSectionSubDiv' style={{ height: '100%' }}>
                <Row justify='center' align='middle' style={{ height: '100%', overflowX: 'hidden' }}>
                    <Col xs={22} sm={22} md={22} lg={20} xl={20} style={{ height: '100%', marginTop: '200px', marginBottom: '100px', paddingBottom: '100px' }}>

                        {/* <h3 className='introSectionText' style={{ paddingRight: '20px' }}>No external platform needed.</h3> */}

                        <Row justify='center' >
                        <h3 className='introSectionText' style={{ paddingRight: '20px' }}>Just </h3>
                        <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#c6e0f9' }}>you. </h3>
                        <h3 className='introSectionText' style={{ paddingRight: '20px' }}>And</h3>
                        <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#F9C6CB' }}>me. </h3>
                        <h3 className='introSectionText' > And our dreams.</h3>
                        </Row>
                        <Row justify='center' >
                        <h3 className='introSectionText' style={{ paddingRight: '20px' }}>Let´s have some </h3>
                        <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#F9C6CB' }}>fun </h3>
                        <h3 className='introSectionText' > together!</h3>
                        </Row>
                        <br />
                        <br />
                        <div className='buyNFTButton' onClick={() => scrollToSection(state?.refs?.formSection)}> Mint soon </div>


                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default BuyNFTSectionOne