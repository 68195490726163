import { UserOutlined } from '@ant-design/icons';
import { Carousel, Row, Col, Card, Typography, Avatar } from 'antd';
import CountDown from "count-down-react";

import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import AppVideosSection from './AppVideosSection';
const { Title, Text } = Typography;
const { Meta } = Card;

const CoundownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
        <div className="container">


            < Row gutters={[40, 40]} justify="center" style={{ paddingTop: 0 }}>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                    <div className='timerCard' style={{ padding: '10px' }}>
                        <h2 className='timerNumber' style={{ color: 'black' }}>{days}</h2>
                        <h3 className='timerTime' style={{ color: 'black' }}>days</h3>

                    </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className='timerCard' style={{ padding: '10px' }}>
                        <h2 className='timerNumber' style={{ color: 'black' }}>{hours}</h2>
                        <h3 className='timerTime' style={{ color: 'black' }}>hours</h3>

                    </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className='timerCard' style={{ padding: '10px' }}>
                        <h2 className='timerNumber' style={{ color: 'black' }}>{minutes}</h2>
                        <h3 className='timerTime' style={{ color: 'black' }}>min</h3>

                    </div >
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className='timerCard' style={{ padding: '10px' }}>
                        <h2 className='timerNumber' style={{ color: 'black' }}>{seconds}</h2>
                        <h3 className='timerTime' style={{ color: 'black' }}>sec</h3>

                    </div>
                </Col>

            </Row>






            {/* <ul>
                <li>
                    <span id="days">{days}</span>days
                </li>
                <li>
                    <span id="hours">{hours}</span>Hours
                </li>
                <li>
                    <span id="minutes">{minutes}</span>Minutes
                </li>
                <li>
                    <span id="seconds">{seconds}</span>Seconds
                </li>
            </ul> */}
        </div >
    );
};


const AppTimerBar2 = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const date1 = Date.now() + 26 * 60 * 60 * 1000;

    const timer2 = useRef();

    useEffect(() => {
        if (timer2 !== state.refs.timer2) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'timer2': timer2 } })
        }
    }, [state])



    var [date, setDate] = useState(new Date());

    useEffect(() => {

        var timer = setInterval(() => {
            setDate(new Date())
            const innerDate = Date.now()
            console.log('date', Date.now())
            console.log('date', date)
            // console.log('date', Date.now() - 1667579415000)
            console.log('timer', timer)
            if (innerDate >= 1668452400000) {

                clearInterval(timer)
            }


        }, 10000)
        return function cleanup() {
            clearInterval(timer)
        }

    }, []);

    if (date < 1668452400000) {

        return (
            <>
                <div className='countdownSection' style={{
                    backgroundColor: 'white', marginBottom: '50px'
                }}>

                    <Row ref={timer2} justify="center" align='middle' style={{ paddingTop: '50px', paddingBottom: '50px', }}>
                        <Col xs={22} sm={22} md={22} lg={16} xl={16}>


                            {/* below is real countdown for monday */}
                            <CountDown date={1668452400000} renderer={CoundownRenderer} />


                            {/* <Row gutters={[40, 40]} justify="center" style={{ paddingTop: 0 }}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>10</h2>
                                    <h3 className='timerTime'>days</h3>

                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>07</h2>
                                    <h3 className='timerTime'>hours</h3>

                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>04</h2>
                                    <h3 className='timerTime'>minutes</h3>

                                </div >
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>01</h2>
                                    <h3 className='timerTime'>seconds</h3>

                                </div>
                            </Col>

                        </Row> */}
                            {/* <h1 className='timerNumber'>untill the NFT Drop</h1> */}

                        </Col>
                    </Row>
                </div>

            </>
        )
    } else {
        return (
            <>
                <AppVideosSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
            </>
        )
    }

}

export default AppTimerBar2