import React, { useState, useEffect, useRef, useId } from 'react';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';
import { motion } from 'framer-motion'


const ExplainSectionFour = ({ state, dispatch, stateTwo, dispatchTwo }) => {



    const ExplainFour = useRef(null);

    useEffect(() => {
        if (ExplainFour !== state.refs.ExplainFour) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'ExplainFour': ExplainFour } })
        }
    }, [state])



    const container = {
        hidden: {
            // opacity: 0,
            transition: {
                duration: 1,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            // opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.6,
                staggerDirection: 1,
                staggerChildren: 0.5
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -100,
            // y: 100,
            rotate: 2,
            scale: 0,
            transition: {
                // delay: 1,
                type: "spring",
                duration: 2.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                // delay: 1,
                type: "spring",
                duration: 1,
                bounce: 0.1,

            }
        }
    }


    return (
        <div className='explainSectionThreeMainDiv' >
            <div className='explainSectionThreeSubOne' style={{ height: '100%' }}>
                <div ref={ExplainFour} className='explainSectionThreeSubTwo' style={{ height: '100%' }} >

                    <Row justify='center' align='middle' style={{ height: '100%' }} >
                        <Col xs={22} sm={22} md={22} lg={20} xl={20} style={{ height: '100%', marginTop: '100px', marginBottom: '150px' }}>
                            <h3 className='introSectionText' style={{ paddingRight: '20px' }}> </h3>

                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                                // transition={{staggerChildren: 1}}
                            >
                                <Row justify='center' style={{ paddingBottom: '0px' }}>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>There are only 1000 NFT's  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>that unlock my  </h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#c6e0f9', margin: '0px' }}   variants={item}>exclusive </motion.h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>community.</h3>
                                </Row>
                                <br />
                                <br />
                                <Row justify='center' style={{ paddingBottom: '50px' }}>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>Be the   </h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#F9C6CB', margin: '0px' }}   variants={item}> first </motion.h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}> to own a key  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}> to enter my private world  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}> Before they're   </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}> all gone. </h3>
                                </Row>
                                <br />
                                <br />
                                <Row justify='center' style={{ paddingBottom: '0px' }}>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>Elsa Jean NFT's  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>give you   </h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#c6e0f9', margin: '0px' }}   variants={item}>long term </motion.h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>access to me</h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>as long as you</h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000', margin: '0px' }}>hold the NFT.</h3>
                                </Row>

                            </motion.div>


                        </Col>
                    </Row>
                </div>
            </div>
        </div>

    )
}

export default ExplainSectionFour