import { UserOutlined } from '@ant-design/icons';
import { Carousel, Row, Col, Card, Typography, Avatar } from 'antd';
import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import VideoJS from '../functions/VideoJS';
// import 'video.js/dist/video-js.css';

import videoElsa from '../images/videoElsa.mp4'
import ElsaExplainVideo from '../images/ElsaExplainVideo.mp4'
import ElsaExplainVideo2 from '../images/ElsaExplainVideo2.mp4'

const { Title, Text } = Typography;
const { Meta } = Card;


const VideoOne = ({ state, dispatch, stateTwo, dispatchTwo }) => {


  const videoOne = useRef();

  useEffect(() => {
    if (videoOne !== state.refs.videoOne) {
      dispatch({ type: 'setRefs', payload: { ...state.refs, 'videoOne': videoOne } })
    }
  }, [state])



  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: false,
    muted: true,
    controls: true,
    responsive: true,
    fluid: true,
    // height: '100%',
    sources: [{
      // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
      src: ElsaExplainVideo,
      type: 'video/mp4'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };






  return (
    <>


      <div ref={videoOne} className='videoOneSection' style={{
        // height: '80vh',
        height: '100%',
        width: '100%'
      }}>

        <div display='flex'
          justify-content='center'
          style={{
            padding: '56.25% 0 0 0',
            position: 'relative',
            // margin: '40px'
          }}
        //  style={"padding:56.25% 0 0 0;position:relative;"}
        >
          <iframe src="https://player.vimeo.com/video/764900920?h=8057f91413&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
            // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%', height: '100%'
            }}
            title="ElsaExplainVideo">
            <video style={{ margin: '0px', objectFit: 'cover' }} />
          </iframe>
        </div>
        {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady}>
          <source src='https://drive.google.com/uc?export=download&id=1vNbnsOU4-LxBg1zSJAMXkjBN8iWhQh-E' type='video/mp4'> </source>
        </VideoJS> */}
        {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}

        {/* <iframe style={{ height: '100%', width: '100%' }} frameBorder="0" src="https://drive.google.com/file/d/1vNbnsOU4-LxBg1zSJAMXkjBN8iWhQh-E/preview"></iframe> */}

        {/* <iframe width={'100%'} height={'100%'} src="https://www.youtube.com/embed/xNRJwmlRBNU?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}


        {/* <iframe className='videoOneVideo' style={{ height: '100%', width: '100%' }} src="https://www.youtube.com/embed/DipTRbWdp5s?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}


        {/* <iframe className='videoOneVideo' width={'100%'} height={'100%'} src="https://www.youtube.com/embed/xNRJwmlRBNU?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        {/* <iframe width={'100%'} height={'100%'} src="videoElsa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        {/* <video className='videoOneVideo' width={'100%'} height={'100%'} src={videoElsa}></video> */}
      </div>
    </>
  )
}

export default VideoOne