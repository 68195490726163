import React, { useState, useEffect, useRef, useId } from 'react';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';
import { motion } from 'framer-motion'


const ExplainSectionThree = ({ state, dispatch, stateTwo, dispatchTwo }) => {



    const ExplainThree = useRef(null);

    useEffect(() => {
        if (ExplainThree !== state.refs.ExplainThree) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'ExplainThree': ExplainThree } })
        }
    }, [state])



    const container = {
        hidden: {
            // opacity: 0,
            transition: {
                duration: 1,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            // opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.6,
                staggerDirection: 1,
                staggerChildren: 0.5
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -100,
            // y: 100,
            rotate: 2,
            scale: 0,
            transition: {
                // delay: 1,
                type: "spring",
                duration: 2.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                // delay: 1,
                type: "spring",
                duration: 1,
                bounce: 0.1,

            }
        }
    }


    return (
        <div className='explainSectionThreeMainDiv' >
            <div className='explainSectionThreeSubOne' style={{ height: '100%' }}>
                <div ref={ExplainThree} className='explainSectionThreeSubTwo' style={{ height: '100%' }} >

                    <Row justify='center' align='middle' style={{ height: '100%' }} >
                        <Col xs={22} sm={22} md={22} lg={20} xl={20} style={{ height: '100%', marginTop: '100px', marginBottom: '100px' }}>
                            <h3 className='introSectionText' style={{ paddingRight: '20px' }}> </h3>

                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                                // transition={{staggerChildren: 1}}
                            >
                                <Row justify='center' style={{ paddingBottom: '0px' }}>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}>The first 1000  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}>people </h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#c6e0f9' }}   variants={item}>signing up </motion.h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}>with their  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}>wallet </h3>
                                </Row>
                                <Row justify='center' style={{ paddingBottom: '50px' }}>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}>will get  </h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#F9C6CB' }}   variants={item}> pre access </motion.h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}>to the  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}> very first  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}> 1000  </h3>
                                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#000000' }}> Community Passes. </h3>
                                </Row>

                            </motion.div>


                        </Col>
                    </Row>
                </div>
            </div>
        </div>

    )
}

export default ExplainSectionThree