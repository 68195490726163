import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import HeaderSection from '../components/Header';
import logo from '../logo.svg';
import Hero from '../components/Hero';
import SideScrollHero from '../components/SideScrollHero';
import Roadmap from '../components/Roadmap';
import Faq from '../components/Faq';
import Art from '../components/Art';
import Team from '../components/Team';
import NFTGateTestSection from '../components/NFTGateTestSection';
// import ContentSection from '../components/ContentSection';

import { BackTop } from 'antd';
import AppHero from '../appComponents/AppHero';
import AppHeader from '../appComponents/AppHeaderSection';
import AppContentSection from '../appComponents/AppContentSection';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppPhotosPage from '../appComponents/AppPhotosPage';





const AppPage = ({ state, dispatch, stateTwo, dispatchTwo }) => {
  const { url, path } = useRouteMatch()



  return (
    <div
      style={{ backgroundColor: '#141414' }}>
      <BackTop />
      <AppHeader state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

      {/* <Switch>

        <Route path={`${path}/photos`}>
          <AppPhotosPage state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

        </Route>
      </Switch> */}
      {/* <SideScrollHero /> */}

      <AppHero state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

      <AppContentSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
      {/* <NFTGateTestSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}

      {/* <Roadmap state={state} dispatch={dispatch} /> */}
      {/* <Art state={state} dispatch={dispatch}  /> */}
      {/* <Team state={state} dispatch={dispatch}  /> */}
      {/* <Faq state={state} dispatch={dispatch}  /> */}


    </div>
  )
}

export default AppPage