import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import logo from '../logo.svg';
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';
// import heroImage from '../images/heroImage.png'
import Wireframe1 from '../images/Wireframe1.png'
import WireframeTrans3 from '../images/WireframeTrans3.png'
import NFTExample from '../images/NFTExample.jpg'

import { motion } from 'framer-motion'
import HeaderSection from '../components/Header';

const { Title } = Typography;



const HeroBuy = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const heroBuy = useRef(null);

    useEffect(() => {
        if (heroBuy !== state.refs.heroBuy) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'heroBuy': heroBuy } })
        }
    }, [state])


    const scrollToSection = (elementRef) => {
        // console.log('elementRef', elementRef)
        if (elementRef?.current) {

            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: "auto",
            })
        }
    }



    const container = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.5
            }
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.5
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -800,
            // y: -200,
            rotate: 2,
            transition: {
                type: "spring",
                duration: 1,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                duration: 1,
                bounce: 0.1,

            }
        }
    }




    return (
        <div ref={heroBuy} className='heroSection' >
            <div className='heroSubDiv' style={{ height: '100%' }}>
            <HeaderSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

                <Row gutter={50} justify='center' style={{ width: '100%', paddingTop: '100px', paddingBottom: '50px', }}>

                    <Col xs={22} sm={14} md={14} lg={14} xl={14} align='center' style={{ paddingBottom: '150px' }}>


                        <motion.div
                            align='left'
                            variants={container}
                            initial={'hidden'}
                            whileInView={'show'}
                            viewport={{ once: false, amount: 0.1 }}
                        // animate='show'
                        // transistion={{ staggerChildren: 0.5, delayChildren: 0.5 }}
                        >

                            <motion.h2 className='mainSubTitle' variants={item}  >
                                Will YOU take
                            </motion.h2>
                            <motion.h1 className='mainTitle' variants={item}  >Elsa Jean's</motion.h1>
                            <motion.div variants={item} >
                                <Row  >
                                    <h2 className='mainSubTitle' style={{ marginRight: '20px' }}>
                                        NFT
                                    </h2>
                                    <h2 className='mainSubTitle' style={{ color: '#F9C6CB', marginRight: '30px' }}>
                                        virginity
                                    </h2>
                                </Row>
                            </motion.div>

                        </motion.div>



                        <div style={{ width: '100%' }}>

                            <div className='mainCTAButton' target="_blank" >
                                <div className='mainCTAButtonText' onClick={() => scrollToSection(state.refs.buySection)}>MINT NOW !</div>
                            </div>
                        </div>


                    </Col>
                    <Col xs={22} sm={8} md={8} lg={8} xl={8}>


                    </Col>
                </Row>
            </div>
        </div>


    )
}

export default HeroBuy