import { UserOutlined } from '@ant-design/icons';
import { Carousel, Row, Col, Card, Typography, Avatar } from 'antd';
import CountDown from "count-down-react";

import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Link } from 'react-router-dom';
const { Title, Text } = Typography;
const { Meta } = Card;


const CoundownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
        <div className="container">
            {/* <h1 className='timerNumber'>Countdown</h1> */}

            {
                completed === true ?
                    <Col
                        style={{ backgroundColor: 'white' }}
                    >

                        <div className='countdownSection'>

                            <Row justify="center" style={{ paddingTop: '10px', paddingBottom: '10px', }}>
                                <Col span={22} >
                                    <h2 className='mintingNowText'>Private affaire is LIVE !</h2>

                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{days}</h2>
                                            <h3 className='timerTime'>days</h3>

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{hours}</h2>
                                            <h3 className='timerTime'>hours</h3>

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{minutes}</h2>
                                            <h3 className='timerTime'>min</h3>

                                        </div >
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{seconds}</h2>
                                            <h3 className='timerTime'>sec</h3>

                                        </div>
                                    </Col>
                            </Row>

                        </div>
                        <br />
                        <br />
                        <h3 className='timerTime' style={{ color: '#F9C6CB', fontSize: '35px' }}>Open app :</h3>
                        <Row justify='center'>

                            <Col
                                // style={{ width: '100%' }}
                                style={{ height: '100%', alignContent: 'middle' }}
                            >
                                <Link to='/app' style={{ height: '100%', alignContent: 'middle' }}>
                                    <div className='connectWalletButton' target="_blank" >
                                        <div className='connectWalletButtonText' style={{  fontSize: '35px', paddingLeft: '10px', paddingRight: '10px' }}>
                                            App
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br />
                        <br />

                    </Col>
                    :
                    // null

                    <div className='countdownSection'>

                        <Row justify="center" style={{ paddingTop: '10px', paddingBottom: '10px', }}>
                            <Col xs={22} sm={22} md={22} lg={16} xl={16}>

                                <Row gutters={[40, 40]} justify="center" style={{ paddingTop: 0 }}>

                                    {/* <Col span={22} >
                                        <h2 className='mintingNowText'>Private affaire is LIVE !</h2>

                                    </Col> */}
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{days}</h2>
                                            <h3 className='timerTime'>days</h3>

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{hours}</h2>
                                            <h3 className='timerTime'>hours</h3>

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{minutes}</h2>
                                            <h3 className='timerTime'>min</h3>

                                        </div >
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className='timerCard' style={{ padding: '10px' }}>
                                            <h2 className='timerNumber'>{seconds}</h2>
                                            <h3 className='timerTime'>sec</h3>

                                        </div>
                                    </Col>



                                </Row>
                            </Col>
                        </Row>
                    </div>

            }

            {/* <ul>
                <li>
                    <span id="days">{days}</span>days
                </li>
                <li>
                    <span id="hours">{hours}</span>Hours
                </li>
                <li>
                    <span id="minutes">{minutes}</span>Minutes
                </li>
                <li>
                    <span id="seconds">{seconds}</span>Seconds
                </li>
            </ul> */}
        </div>
    );
};


const TimerBarBought = ({ state, dispatch }) => {
    const date1 = Date.now() + 26 * 60 * 60 * 1000;

    const timerBought = useRef();

    useEffect(() => {
        if (timerBought !== state.refs.timerBought) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'timerBought': timerBought } })
        }
    }, [state])

    return (
        <>
            <div ref={timerBought}>


                {/* <CountDown date={1667829592000} renderer={CoundownRenderer} /> */}
                <CountDown date={1667952000000} renderer={CoundownRenderer} />  
                {/* <Row gutters={[40, 40]} justify="center" style={{ paddingTop: 0 }}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>10</h2>
                                    <h3 className='timerTime'>days</h3>

                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>07</h2>
                                    <h3 className='timerTime'>hours</h3>

                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>04</h2>
                                    <h3 className='timerTime'>minutes</h3>

                                </div >
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='timerCard' style={{ padding: '20px' }}>
                                    <h2 className='timerNumber'>01</h2>
                                    <h3 className='timerTime'>seconds</h3>

                                </div>
                            </Col>

                        </Row> */}
                {/* <h1 className='timerNumber'>untill the NFT Drop</h1> */}


            </div>

        </>
    )
}

export default TimerBarBought