import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';



const WhyCardanoSection4 = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const why4 = useRef();

    useEffect(() => {
        if (why4 !== state?.refs?.why4) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'why4': why4 } })
        }
    }, [state])


    return (
        <div ref={why4} className='why4' style={{
            // height: '80vh',
        }}>
            <Row justify='center' className='why4SubDiv' style={{ height: '100%', paddingBottom: '150px', paddingTop: '100px' }}>
                <Col xs={20} sm={18} md={16} lg={16} xl={16} style={{ height: '100%' }}>
                    <Row justify='center'>
                        <h3 className='whyh2' style={{ paddingRight: '20px' }}>04 </h3>
                    </Row>
                    <Row justify='center' style={{ paddingBottom: '50px' }}>
                        <h3 className='whyh2' style={{ paddingRight: '20px' }}>Stability</h3>
                        {/* <h3 className='whyh2' style={{ paddingRight: '20px' }}>Technological advancements backed by the research-first approach</h3> */}
                    </Row>


                    <Row justify='center' style={{ 
                        // paddingBottom: '100px' 
                        }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row justify='center' >
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> The  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  Cardano  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  team  </h1>
                                <h1 className='whyh3' style={{ color: '#00E733', paddingRight: '20px', textAlign: 'left' }}> regularly  </h1>
                                <h1 className='whyh3' style={{ color: '#00E733',paddingRight: '20px', textAlign: 'left' }}>  audits   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  the   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  codebase  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> to   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> ensure   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> that  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  vulnerabilities   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  are   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  quickly   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  addressed.  </h1>
                                {/* <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> The people  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> behind  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Cardano  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> are   </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> constantly  </h1>
                                <h1 className='whyh3' style={{ color: '#37F661', paddingRight: '20px', textAlign: 'left' }}>upgrading </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> the  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> network.   </h1> */}
                            </Row>
                        </Col>
                        {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        </Col> */}
                    </Row>

{/* 
                    <Row justify='center' style={{ paddingBottom: '100px' }}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> After testing </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> phases Alonzo</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> eventually became </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> part of </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> mainnet, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> allowing the </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> developers access </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> to advanced  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Haskell language,  </h1>
                            </Row>
                            <Row justify='center'>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> notably </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> also </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> used </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> by the </h1>
                                <h1 className='whyh3' style={{ color: '#FF6161', paddingRight: '20px', textAlign: 'left' }}>US military</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> & </h1>
                                <h1 className='whyh3' style={{ color: '#6461FF', paddingRight: '5px', textAlign: 'left' }}>NASA</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> . </h1>
                            </Row>

                        </Col>
                    </Row> */}

{/* 

                    <Row justify='center' style={{ paddingBottom: '50px' }}>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Unlike other </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> blockchains, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Cardano prioritizes </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> peer-reviewed </h1>
                                <h1 className='whyh3' style={{ color: '#FF9BBF', paddingRight: '20px', textAlign: 'left' }}>academic research</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> and proposals </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> before releasing </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> any new </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> product,  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> service, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> or update.  </h1>
                            </Row>
                            <Row justify='center'>
                           
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Ouroboros enabling </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> the PoS feature </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> is one example </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> of this rigorous </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> scientific procedure. </h1>
                            </Row>

                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        </Col>
                    </Row> */}



                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoSection4


