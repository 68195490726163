import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography, Space } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import VideoJS from '../functions/VideoJS';


const { Title, Text } = Typography;




const AppExplainSectionOne = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: false,
        muted: false,
        controls: true,
        controlBar: {
            fullscreenToggle: true,
            pictureInPictureToggle: false,
            remainingTimeDisplay: true,
            volumePanel: true,
            currentTimeDisplay: true,
            timeDivider: true,
            durationDisplay: true
        },
        responsive: true,
        fluid: true,
        // height: '100%',
        style: { borderRadius: '20px', width: '100%', height: "50%" },
        // borderRadius: '40px',
        sources: [{
            // 1A6jpd2FW531PawLP3GAUj9pVJd1ZIBG0
            // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
            src: 'https://drive.google.com/uc?export=download&id=1A6jpd2FW531PawLP3GAUj9pVJd1ZIBG0',
            // src: Rick,
            // src: ElsaExplainVideo,
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };






    if (stateTwo?.hasNFT === true) {

        return (

            <div className='NFTGateSection' style={{
            }}>
                <Row justify='center' >
                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>

                        <Row justify='center' gutter={[60, 60]} className='NFTGateSectionSubDiv' style={{
                            height: '100%',
                            //  paddingTop: '100px', 
                            paddingBottom: '100px'
                        }}>
                            {/* <Col xs={24} sm={12} md={8} lg={8} xl={6}>


                                <iframe

    src={'https://drive.google.com/uc?export=download&id=1A6jpd2FW531PawLP3GAUj9pVJd1ZIBG0'}
    // src="https://player.vimeo.com/video/765825293?h=0533c99cfb"
    // src="https://player.vimeo.com/video/766471506?h=990bf633b9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullScreen
    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
    // style="position:absolute;top:0;left:0;width:100%;height:100%;"
    title="elsaStreamHeroVideo"
>

</iframe>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />


                            </Col> */}

                            <Col xs={24} sm={20} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                                <p className='AppExplainText' style={{ marginBottom: '0px' }}> Think about how we shall spend this beautiful evening together…  </p>
                                {/* <br /> */}
                                {/* <br /> */}
                                <p className='AppExplainText' style={{ marginBottom: '0px' }}> Maybe with a candle light dinner? </p>
                                <p className='AppExplainText' style={{ marginBottom: '0px' }}> Or some music? </p>
                                <p className='AppExplainText' style={{ marginBottom: '0px' }}> Or we sit outside on the balcony and look at the stars! </p>

                                <br />
                                <br />
                                <p className='AppExplainText' style={{ marginBottom: '0px' }}> Just you. And me. And our dreams. </p>
                                <br />
                                <br />
                                <br />
                                <br />
                                <p className='AppExplainText' style={{ marginBottom: '0px' }}> I will post unique content. Every Monday. <br />The first videos will go live in…
                                </p>



                            </Col>

                        </Row>
                    </Col>
                </Row>

            </div>
        )


    } else {
        return (
            null
            // <div>
            //     <Row justify='center' className='NFTGateSectionSubDiv' style={{
            //         height: '100%',
            //         //  paddingTop: '100px', 
            //         paddingBottom: '100px'
            //     }}>
            //         <Col xs={22} sm={12} md={6} lg={6} xl={6}>

            //             <Card
            //                 hoverable
            //                 style={{
            //                     // width: artSize,
            //                     borderRadius: 40,
            //                 }}
            //                 cover={<img alt="example" src={holoman} style={{
            //                     borderTopLeftRadius: 40,
            //                     borderTopRightRadius: 40,
            //                 }} />}
            //                 actions={[
            //                 ]}
            //             >
            //                 <h3 className='buyNFTButtonText'  style={{ color: 'black' }}>
            //                     holoman
            //                 </h3>
            //                 <br />
            //                 <button className='buyNFTButton'>
            //                     <h3 className='buyNFTButtonText'  >
            //                         Get access
            //                     </h3>
            //                 </button >


            //                 {/* <Button justify='center' align='middle' size='large' block={true}
            //                 // onClick={() => window.open(image.buyLink)} target="_blank" style={{ borderRadius: 60, padding: 10, height: 'auto' }}
            //                 >
            //                     <div>
            //                         <h3 className='buyNFTButtonText' style={{ color: 'black' }} >
            //                             Get access
            //                         </h3>
            //                         <Title style={{ paddingTop: 4, color: 'black' }} level={3}>Buy Now!</Title>
            //                         <Space>
            //                         </Space>
            //                     </div>
            //                 </Button> */}
            //             </Card>


            //         </Col>
            //     </Row>
            // </div>
        )
    }



}

export default AppExplainSectionOne
