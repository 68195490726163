import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';


const WhyCardanoHeroBar = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const whyHeroBar = useRef();

    useEffect(() => {
        if (whyHeroBar !== state?.refs?.whyHeroBar) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'whyHeroBar': whyHeroBar } })
        }
    }, [state])


    return (
        <div ref={whyHeroBar} className='whyHeroBarSection' style={{
            // height: '80vh',
        }}>
            <div className='whyBarSubDiv'>

                <Row justify='center' style={{ height: '100%', paddingTop: '50px', paddingBottom: '50px' }} >
                    <Col span={20} style={{ height: '100%' }}>
                        <Row justify='center' align='middle' style={{ height: '100%' }}>
                            <Col >
                                <Row justify='center'>
                                    <h2 className='whyBar' style={{ paddingRight: '20px', textAlign: 'center', margin: '0px' }}>Low fees, fast and sustainable.</h2>
                                    {/* <h2 className='whyBar' style={{ paddingRight: '20px' }}>The Cardano blockchain - low fees, highly scalable, fast and sustainable.</h2> */}
                                </Row>
                                {/* <Row>
                                    <h1 className='whyh1' style={{ color: '#F9C6CB', paddingRight: '20px' }}>WHY</h1>
                                    <h1 className='whyh1' style={{ paddingRight: '20px' }}>WE BUILD </h1>
                                </Row>
                                <Row>
                                    <h1 className='whyh1'>ON CARDANO</h1>
                                </Row> */}

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default WhyCardanoHeroBar