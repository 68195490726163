import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import logo from '../logo.svg';
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';
// import heroImage from '../images/heroImage.png'
// import Wireframe1 from '../images/Wireframe1.png'
// import WireframeTrans3 from '../images/WireframeTrans3.png'
import VideoJS from '../functions/VideoJS';
// import HeroBG from '../images/HeroBG.mp4';
import elsaStreamHeroVideo from '../images/elsaStreamHeroVideo.mp4'



const { Title } = Typography;


const AppHero = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const appHero = useRef(null);

    useEffect(() => {
        if (appHero !== state.refs.appHero) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'appHero': appHero } })
        }
    }, [state])






    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: true,
        muted: true,
        controls: false,
        responsive: true,
        playsInline: true,
        fluid: true,
        width: '100%',
        // height: '100%',
        sources: [{
            // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
            // src: HeroBG,
            // src: ElsaExplainVideo,
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };





    return (
        <Col>
            <div className="video-wrapper">
                {/* <VideoJS className='videoHeroBG' options={videoJsOptions} onReady={handlePlayerReady} /> */}

                <video className='videoHeroBG' playsInline autoPlay muted loop >
                    {/* <source src={HeroBG} type="video/webm" /> */}
                    <source src={elsaStreamHeroVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {/* <div className='darkenScreen'>
                </div> */}

                <Row ref={appHero}
                    // className='heroSection'
                    justify='center' align='middle' style={{
                        // paddingTop: '100px',
                        // paddingBottom: '100px',
                        // height: '80vh',
                        width: '100%',
                        height: '100%',
                    }}>
                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                        <br />
                        <br />
                        <br />

                        <Row justify='space-around'
                        align='middle'
                            style={{
                                // padding: '50px', 
                                paddingBottom: '100px'
                            }}
                            gutter={[40, 40]}
                        >
                            <Col xs={24} sm={14} md={16} lg={16} xl={16}>


                                <div

                                >

                                    <Row justify='start' style={{ margin: 0 }}>
                                        <h3 className='appHeroTitle' style={{ paddingRight: '20px', margin: 0 }} >Just</h3>
                                        <h3 className='appHeroTitle' style={{ paddingRight: '20px', color: '#c6e0f9', margin: 0 }} >you. </h3>
                                        <h3 className='appHeroTitle' style={{ paddingRight: '20px', margin: 0 }} >And</h3>
                                        <h3 className='appHeroTitle' style={{ paddingRight: '20px', color: '#F9C6CB', margin: 0 }} >me. </h3>
                                    </Row>
                                    <Row justify='start' style={{ margin: 0 }}>
                                        <h3 className='appHeroTitle' style={{ margin: 0 }}> And our dreams.</h3>
                                    </Row>


                                    {/* <h1 className='appHeroTitle'
                                style={{ margin: 0 }}
                            >Just you. And me. </h1>
                            <h1 className='appHeroTitle'> And our dreams. </h1> */}


                                    <p
                                        className='AppHeroSubtitle'
                                        align='left'
                                        style={{
                                            color: 'white',
                                            // fontSize: '18px'
                                        }}>
                                        Don´t we all hate Mondays?  From now on, this will change drastically!<br />

                                        Week by week it will get more intense…

                                        Like a wonderful romantic affaire…<br />
                                        <br />
                                        I am waiting for you in my hotel room… are you joining?

                                    </p>
                                    {/* <img src={WireframeTrans3} alt="heroImage" style={{ height: '10%', width: '100%', padding: '20px' }} /> */}
                                </div>
                                <div
                                    style={{ height: '120px' }}
                                >

                                </div>


                            </Col>
                            {stateTwo?.hasNFT === true ?

                                < Col xs={24} sm={10} md={8} lg={8} xl={6}>
                                    <div
                                        style={{ padding: '177.78% 0 0 0', position: 'relative' }}

                                    // style="padding:177.78% 0 0 0;position:relative;"
                                    >
                                        <iframe
                                            src="https://player.vimeo.com/video/766471506?h=990bf633b9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowfullscreen
                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}

                                            // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                            title="Placeholder 01"
                                        ></iframe>
                                    </div>
                                </Col>
                            : null }

                        </Row>
                    </Col>


                    {/* <Col xs={22} sm={8} md={8} lg={8} xl={8}>
                <Title>Wireframe</Title>
                <br />
                <br />
                <p>
                    Wireframe is a template React js Cardano NFT site.
                    You can copy the code from github to get your own project going.
                    You can use the site with NMKR.io to mint NFT's on the Cardano Blockchain.
                </p>
                <br />
                <br />

                <Button size='large' type='primary' block={true} target="_blank" style={{borderRadius: 20}} href='https://github.com/NotaCodeur/Cardano-NFT-DAPP'>
                    Call To Action !
                </Button>
                {/* <Row align='center' justify='center'>
                    <Col span={24} align='center' style={{
                        position: 'absolute',
                        bottom: 20,
                        zIndex: 1,
                        transition: 'all 0.2s',
                    }}>
                        <Row
                            className='createListBottomRow'
                            gutter={[20, 20]}
                            justify='center'
                        >
                            <Col span={24}  align='center'>
                                <Button block={true}>Call To Action !</Button>
                            </Col>
                        </Row>
                    </Col>

                </Row> */}
                    {/* </Col> */}



                </Row >

            </div >

        </Col >

    )
}

export default AppHero