import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography, Space } from 'antd';
import { Link } from 'react-router-dom';
import VideoJS from '../functions/VideoJS';
// import Rick from '../images/Rick.mp4';
// import holoman from '../images/hologif.gif';
// import elsaLockBlur from '../images/elsaLockBlur.jpg';
// import NFTGateTestSection from './NFTGateTestSection';
import AppVideosSection from './AppVideosSection';
import AppPhotosSection from './AppPhotosSection';
import AppChatSection from './AppChatSection';
import AppAudioSection from './AppAudioSection';
import AppExplainSectionOne from './AppExplainSectionOne';
import AppTimerBar2 from './AppTimerBar2';

const { Title, Text } = Typography;



const AppContentSection = ({ state, dispatch, stateTwo, dispatchTwo }) => {

    const [hasNFT, setHasNFT] = useState(false);

    // const NFTGateTest = useRef();

    // useEffect(() => {
    //     if (NFTGateTest !== state?.refs?.NFTGateTest) {
    //         dispatch({ type: 'setRefs', payload: { ...state?.refs, 'NFTGateTest': NFTGateTest } })
    //     }
    // }, [state])





    useEffect(() => {
        console.log('hasNFT changing', hasNFT)


    }, [hasNFT])








    if (stateTwo?.hasNFT === true) {

        return (
            <div>
                <AppPhotosSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
                <AppExplainSectionOne state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
                <Col span={24}>
                    {/* timer bar also renders video section */}
                    <AppTimerBar2 state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
                </Col>
                 <AppAudioSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
                <AppChatSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> 

                {stateTwo?.hasAdminNFT === true ?
                    // <AppVideosSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
                    null
                    : null
                }
                {/* <NFTGateTestSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
                {/* <AppAudioSection  state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}

            </div>
        )

    } else {
        return (
            <div>
                <Row justify='center' className='NFTGateSectionSubDiv' style={{
                    height: '100%',
                    //  paddingTop: '100px', 
                    paddingBottom: '100px'
                }}>
                    <Col xs={18} sm={12} md={8} lg={6} xl={6}>

                        <Card
                            hoverable
                            style={{
                                // width: artSize,
                                borderRadius: 40,
                            }}
                            cover={<img alt="example" src={'https://drive.google.com/uc?id=1QCaG8BkeactvanqoeeP6Fo6g6JSVm63n'} style={{
                                size: 'cover', width: '100%',
                                // height: '60%',
                                objectFit: 'cover',
                                aspectRatio: '1/1',
                                objectPosition: '50% 0%',
                                borderTopLeftRadius: 40,
                                borderTopRightRadius: 40,
                            }} />}
                            actions={[
                            ]}
                        >
                            <h3 className='appBuyNFTButtonText' style={{ color: 'black' }}>
                                CLUB ELSA JEAN
                            </h3>
                            <br />
                            <button className='appBuyNFTButton' onClick={() => window.open('https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=1')} target="_blank">
                                <h3 className='appBuyNFTButtonText'  >
                                    Get access
                                </h3>
                            </button >


                            {/* <Button justify='center' align='middle' size='large' block={true}
                            // onClick={() => window.open(image.buyLink)} target="_blank" style={{ borderRadius: 60, padding: 10, height: 'auto' }}
                            >
                                <div>
                                    <h3 className='buyNFTButtonText' style={{ color: 'black' }} >
                                        Get access
                                    </h3>
                                    <Title style={{ paddingTop: 4, color: 'black' }} level={3}>Buy Now!</Title>
                                    <Space>
                                    </Space>
                                </div>
                            </Button> */}
                        </Card>


                    </Col>
                </Row>
            </div>
        )
    }



}

export default AppContentSection
