import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';



const WhyCardanoSection6 = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const why6 = useRef();

    useEffect(() => {
        if (why6 !== state?.refs?.why6) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'why6': why6 } })
        }
    }, [state])


    return (
        <div ref={why6} className='why6' style={{
            // height: '80vh',
        }}>
            <Row justify='center' className='why6SubDiv' style={{ height: '100%', paddingBottom: '250px', paddingTop: '100px' }}>
                <Col xs={20} sm={18} md={16} lg={16} xl={16} style={{ height: '100%' }}>
                    <Row justify='center'>
                        <h3 className='whyh2' style={{ paddingRight: '20px' }}>06 </h3>
                    </Row>
                    <Row justify='center' style={{ paddingBottom: '50px' }}>
                        <h3 className='whyh2' style={{ paddingRight: '20px' }}> Sustainable </h3>
                        {/* <h3 className='whyh2' style={{ paddingRight: '20px' }}>Security and stability</h3> */}
                    </Row>


                    <Row justify='center' style={{ 
                        paddingBottom: '50px' 
                        }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row justify='center' style={{
                                // paddingBottom: '50px'
                            }}>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}> Being  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  THE </h1>
                                <h1 className='whyh3' style={{color: '#00E733', paddingRight: '20px', textAlign: 'center' }}>  GREEN </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  BLOCKCHAIN  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  Cardano  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  offers </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  a </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  better </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  and  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>   environmentally  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  sustainable </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  alternative </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  to</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  other </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}> blockchains. </h1>
                               
                                {/* <h1 className='whyh3' style={{ color: '#AC74F3', paddingRight: '20px', textAlign: 'left' }}>Security </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> and </h1>
                                <h1 className='whyh3' style={{ color: '#FF6AD5', paddingRight: '20px', textAlign: 'left' }}>stability </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> are two </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> essential </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> factors in </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> blockchain </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> technology. </h1> */}
                            </Row>
                            {/* <Row justify='center' >
       
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Cardano </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> uses a </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> unique consensus </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> algorithm </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> called Ouroboros, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> which </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> has been </h1>
                                <h1 className='whyh3' style={{ color: '#50C0FF', paddingRight: '20px', textAlign: 'left' }}>mathematically proven </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> to be </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> secure.  </h1>
                            </Row> */}
                        </Col>
                        {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        </Col> */}
                    </Row>

                    <Row justify='center' >
                        
                        <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row justify='center' style={{ 
                                // paddingBottom: '50px' 
                                }}>
                            <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  Keep</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}> in  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  mind: </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  We  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}> only </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}> have</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'center' }}>  this  </h1>
                                <h1 className='whyh3' style={{ color: '#00B9F3', paddingRight: '20px', textAlign: 'center' }}> one  </h1>
                                <h1 className='whyh3' style={{color: '#00B9F3',  paddingRight: '20px', textAlign: 'center' }}>  planet! </h1>
                            </Row>
                            

                        </Col>
                    </Row>



                    {/* <Row justify='center' >
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Additionally, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  the Cardano team </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> maintains a </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> strong commitment </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> to security </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> and regularly </h1>
                                <h1 className='whyh3' style={{ color: '#17F36F', paddingRight: '20px', textAlign: 'left' }}>audits</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> the codebase </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> to ensure that </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  vulnerabilities </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> are quickly </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> addressed.  </h1>
                            </Row>
                            <Row justify='center'>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Cardano </h1>
                                <h1 className='whyh3' style={{ color: '#EFFF35', paddingRight: '20px', textAlign: 'left' }}>excels</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> in both </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> of these </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> areas,  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> thanks to </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> its well-developed </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> infrastructure. </h1>
                            </Row>

                        </Col>
                    </Row> */}




                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoSection6


