import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';
import { motion } from 'framer-motion'


const IntroSectionTwo = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(null);


    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);
    useEffect(() => {
        if (screenSize > 1368) {
            setActiveMenu(false);
        }
        // else if (screenSize > 1000) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 700) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 500) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 300) {
        //     setActiveMenu(true);
        // }
        // else {
        //     setActiveMenu(true);
        // }
    }, [screenSize]);


    const introTwo = useRef(null);

    useEffect(() => {
        if (introTwo !== state.refs.introTwo) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'introTwo': introTwo } })
        }
    }, [state])



    const container = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 2.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -100,
            // y: 100,
            rotate: 2,
            scale: 0,
            transition: {
                delay: 1,
                type: "spring",
                duration: 5.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                type: "spring",
                duration: 0.5,
                bounce: 0.1,

            }
        }
    }



    return (
        <div className='introSectionTwoMainDiv' style={{ 
            // height: '50vh' 
            
            }}>
            <div className='introSectionTwoSubOne' style={{ height: '100%' }}>
                <div ref={introTwo} className='introSectionTwoSubTwo' style={{ height: '100%' }} >

                    <Row justify='center' align='middle' style={{ height: '100%',
                paddingBottom: '200px',
                paddingTop: '150px',
                }} >
                        <Col xs={22} sm={22} md={22} lg={20} xl={20}>
                            {/* <br />
                            <br />
                            <br />
                            <br /> */}
                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                            >

                                <Row justify='center'>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px' }} variants={item}>Come in  </motion.h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px' }} variants={item}> and enjoy the </motion.h3>
                                    <motion.h3 className='introSectionText' style={{ paddingRight: '20px', color: '#5E78F9' }} variants={item}>Elsa community… </motion.h3>
                                    {/* <h3 className='introSectionText' style={{paddingRight: '20px'}}>And</h3> */}
                                    {/* <h3 className='introSectionText' style={{paddingRight: '20px', color: '#F9C6CB'}}>me. </h3> */}
                                    {/* <h3 className='introSectionText' > And our dreams.</h3> */}

                                </Row>
                            </motion.div>
                            {/* <br />
                            <h3 className='introSectionText'>Come in and enjoy the Elsa community…</h3>
                            <br />
                            <h3 className='introSectionText'>My Elsa Jean Community Pass will give you private access to my little secrets.</h3> */}
                            {/* <br />
                            <br />
                            <br />
                            <br /> */}
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    )
}

export default IntroSectionTwo