import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import HeaderSection from '../components/Header';
import logo from '../logo.svg';
import Hero from '../components/Hero';
import SideScrollHero from '../components/SideScrollHero';
import Roadmap from '../components/Roadmap';
import Faq from '../components/Faq';
import Art from '../components/Art';
import Team from '../components/Team';
import VideoOne from '../components/VideoOne';
import TimerBar from '../components/TimerBar';
import HowItWorks from '../components/HowItWorks';
import LockedContent from '../components/LockedContent';
import IntroSection from '../components/IntroSection';
import IntroSectionTwo from '../components/IntroSectionTwo';
import IntroSectionThree from '../components/IntroSectionThree';
import BuyNFTSectionOne from '../components/BuyNFTSectionOne';
import ExplainSectionOne from '../components/ExplainSectionOne';
import ExplainSectionTwo from '../components/ExplainSectionTwo';
import ExplainSectionThree from '../components/ExplainSectionThree';
import OutroMessageSection from '../components/OutroMessageSection';
import TelegramSection from '../components/TelegramSection';
import LinkToWhySection from '../components/LinkToWhySection';
import NFTGateTestSection from '../components/NFTGateTestSection';
import FormSection from '../components/FormSection';

import { BackTop } from 'antd';
import BuySection from '../components/BuySection';
import HeroBuy from '../buyPageComponents/HeroBuy';
import ArtBuy from '../buyPageComponents/ArtBuy';
import BuyNFTSectionOneBuy from '../buyPageComponents/BuyNFTSectionOneBuy';
import TimerBarBuy from '../buyPageComponents/TimerBarBuy';
import TimerBarBought from '../buyPageComponents/TimerBarBought';
import HeroBought from '../buyPageComponents/HeroBought';
import ExplainSectionFour from '../components/ExplainSectionFour';

const Buypage = ({ state, dispatch, stateTwo, dispatchTwo }) => {

  if (stateTwo?.hasNFT === true) {

    return (
      <div >
        <BackTop />
        {/* <HeaderSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        <HeroBought state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo}  />
        <TimerBarBought state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

      </div>
    )


  } else {
    return (
      <div >
        <BackTop />
        {/* <HeaderSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        {/* <SideScrollHero /> */}
        <HeroBuy state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo}  />
        {/* <Roadmap state={state} dispatch={dispatch} /> */}
        {/* <NFTGateTestSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        <TimerBarBuy state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

        <IntroSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
        <IntroSectionTwo state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
        <VideoOne state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
        <IntroSectionThree state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
        <LockedContent state={state} dispatch={dispatch} />
        <BuyNFTSectionOneBuy state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />



        <BuySection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
        {/* <FormSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo}/> */}
        {/* <ExplainSectionOne state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        {/* <ExplainSectionTwo state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}

        {/* <ExplainSectionFour state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}

        {/* <ExplainSectionThree state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        <ExplainSectionFour state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />
        {/* <OutroMessageSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        <ArtBuy state={state} dispatch={dispatch} />
        {/* <TelegramSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        {/* <LinkToWhySection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}


        {/* <HowItWorks state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}
        {/* <Team state={state} dispatch={dispatch}  /> */}
        {/* <Faq state={state} dispatch={dispatch}  /> */}


      </div>
    )
  }
}

export default Buypage