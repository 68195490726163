import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography, Space } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import VideoJS from '../functions/VideoJS';
// import Rick from '../images/Rick.mp4';
// import holoman from '../images/hologif.gif';
// import images from '../JSON/imageLinks.js'

import { useGetPhotosFolderQuery } from '../services/driveApi';

const { Title, Text } = Typography;


const PhotoWidget = ({ photoId }) => {
    const [skip, setSkip] = useState(true);

    const [source, setSource] = useState('');

    // const { data: photoSrc } = useGetPhotosFolderQuery(source, { skip: skip });



    return (
        <div

            // style="padding:56.25% 0 0 0;position:relative;"
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
        >
            <img
                src={`https://drive.google.com/uc?export=download&id=${photoId}`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", objectFit: 'cover' }}
                alt='asdsfa'
            >

            </img>
        </div>
    )
}


const AppPhotosSection = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const [hasNFT, setHasNFT] = useState(false);


    const [skip1, setSkip1] = useState(true);
    const [hotspotRewardArray, setHotspotAwardArray] = useState([]);
    const { data: allPhotos } = useGetPhotosFolderQuery(hotspotRewardArray, { skip: skip1 });





    useEffect(() => {
        if (skip1) {
            setSkip1(false)
        }

    }, [])

    useEffect(() => {
        if (!skip1) {
            // console.log('allPhotos', allPhotos)
            setSkip1(true)
        }

    }, [allPhotos])




    const { path, url } = useRouteMatch()
    // console.log('url', url)

    // const NFTGateTest = useRef();

    // useEffect(() => {
    //     if (NFTGateTest !== state?.refs?.NFTGateTest) {
    //         dispatch({ type: 'setRefs', payload: { ...state?.refs, 'NFTGateTest': NFTGateTest } })
    //     }
    // }, [state])


    // useEffect(() => {

    //     let userHasNFT = false;

    //     if (stateTwo?.Utxos?.length) {
    //         console.log('Utxos: ', stateTwo?.Utxos)

    //         stateTwo?.Utxos?.map((Utxo) => {
    //             console.log('Utxo in map: ', Utxo)
    //             console.log('Utxo . multiAssetStr : ', Utxo?.multiAssetStr)

    //             if (Utxo?.multiAssetStr?.includes('NMKR')) {
    //                 console.log('Utxo includes NMKR Yay!')
    //                 console.log('set hasNFT to true')
    //                 userHasNFT = true
    //                 if (hasNFT === false) {
    //                     // setHasNFT( true)
    //                 }

    //             }

    //         })

    //         // if (stateTwo?.Utxos?.inlcudes('NMKR')) {
    //         //     console.log(' aray includes !! Utxos: ', stateTwo?.Utxos)

    //         // }
    //     }

    //     if (hasNFT !== userHasNFT) {
    //         console.log(' hasNFT !== userHasNFT, setHasNFT( userHasNFT ) ', userHasNFT)
    //         setHasNFT(userHasNFT)
    //     }

    // }, [stateTwo.Utxos])


    useEffect(() => {
        // console.log('hasNFT changing', hasNFT)


    }, [hasNFT])



    const imageArray = [
        {
            'name': 'image01',
            'id': '1dkSbTnx5gIxN8SP_cXdy_OGKdAEeFsgh',
            'link': 'https://drive.google.com/uc?export=download&id=1dkSbTnx5gIxN8SP_cXdy_OGKdAEeFsgh'
        },
        {
            'name': 'image02',
            'id': '1louTK3fTztWyHnQIeXlQt1jVbBUJTl6U',
            'link': 'https://drive.google.com/uc?export=download&id=1louTK3fTztWyHnQIeXlQt1jVbBUJTl6U'
        },
        {
            'name': 'image03',
            'id': '1Rp6Htz9BR1UXMVute09lx92F-7784s2C',
            'link': 'https://drive.google.com/uc?export=download&id=1Rp6Htz9BR1UXMVute09lx92F-7784s2C'
        },
    ]



    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: false,
        muted: false,
        controls: true,
        responsive: true,
        fluid: true,
        // height: '100%',
        style: { borderRadius: '20px' },
        // borderRadius: '40px',
        sources: [{
            // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
            // src: 'https://drive.google.com/uc?export=download&id=1EA7tS9hEuzVT9idctv1WV2PNPyK-q_L1',
            // src: Rick,
            // src: ElsaExplainVideo,
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };





    if (stateTwo?.hasNFT === true) {

        return (

            <div className='NFTGateSection' style={{
            }}>
                <Row justify='center' className='NFTGateSectionSubDiv' style={{
                    height: '100%',
                    //  paddingTop: '100px', 
                    paddingBottom: '100px'
                }}>

                    <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ height: '100%' }}>
                        <p className='introSectionText' style={{ textAlign: 'left', color: '#F9C6CB', marginBottom: '0px' }}> Private Photo's  </p>
                        <br />
                        <Row justify='start' gutter={[20, 20]}>
                            {/* <Col id={20} xs={24} sm={12} md={6} lg={6} xl={6}>


                                <PhotoWidget photoId={'1louTK3fTztWyHnQIeXlQt1jVbBUJTl6U'} />
                               
                            </Col> */}
                            {allPhotos?.slice(0, 8).map((image, index) => {
                                return (
                                    <Col key={index} xs={24} sm={12} md={6} lg={6} xl={6}>
                                        {/* <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p> */}



                                        <div

                                            // style="padding:56.25% 0 0 0;position:relative;"
                                            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                        >
                                            
                                            <img
                                                src={image.thumbnailLink.slice(0, (image.thumbnailLink.length - 5))+'=s320'}
                                                referrerpolicy="no-referrer"
                                                // src={`https://drive.google.com/uc?export=download&sz=w320&id=${image.id}`}
                                                // src={`https://drive.google.com/uc?export=download&id=${image.id}&s640`}
                                                loading="lazy"
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", objectFit: 'cover' }}
                                                alt='asdsfa'
                                            >

                                            </img>
                                        </div>
                                        {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
                                    </Col>)
                            })}
                            {/* <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>



                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <img
                                        src='https://drive.google.com/uc?export=download&id=1dkSbTnx5gIxN8SP_cXdy_OGKdAEeFsgh'
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", objectFit: 'cover' }}
                                        alt='asdsfa'
                                    >

                                    </img>
                                </div>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/766471506?h=990bf633b9"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="elsaStreamHeroVideo"
                                    >

                                    </iframe>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/766471506?h=990bf633b9"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="elsaStreamHeroVideo"
                                    >

                                    </iframe>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/766471506?h=990bf633b9"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="elsaStreamHeroVideo"
                                    >

                                    </iframe>
                                </div>
                            </Col> */}

                        </Row>
                        <br />
                        <br />
                        <Link to={`${url}/photos` }>
                            <p
                            className='AppHeroSubtitle'
                                //  className='introSectionText'
                                style={{ textAlign: 'middle', color: '#F9C6CB' }}
                            >
                                View all
                            </p>

                        </Link>

                    </Col>

                </Row>
            </div>
        )


    } else {
        return (
            null
            // <div>
            //     <Row justify='center' className='NFTGateSectionSubDiv' style={{
            //         height: '100%',
            //         //  paddingTop: '100px', 
            //         paddingBottom: '100px'
            //     }}>
            //         <Col xs={22} sm={12} md={6} lg={6} xl={6}>

            //             <Card
            //                 hoverable
            //                 style={{
            //                     // width: artSize,
            //                     borderRadius: 40,
            //                 }}
            //                 cover={<img alt="example" src={holoman} style={{
            //                     borderTopLeftRadius: 40,
            //                     borderTopRightRadius: 40,
            //                 }} />}
            //                 actions={[
            //                 ]}
            //             >
            //                 <h3 className='buyNFTButtonText'  style={{ color: 'black' }}>
            //                     holoman
            //                 </h3>
            //                 <br />
            //                 <button className='buyNFTButton'>
            //                     <h3 className='buyNFTButtonText'  >
            //                         Get access
            //                     </h3>
            //                 </button >


            //                 {/* <Button justify='center' align='middle' size='large' block={true}
            //                 // onClick={() => window.open(image.buyLink)} target="_blank" style={{ borderRadius: 60, padding: 10, height: 'auto' }}
            //                 >
            //                     <div>
            //                         <h3 className='buyNFTButtonText' style={{ color: 'black' }} >
            //                             Get access
            //                         </h3>
            //                         <Title style={{ paddingTop: 4, color: 'black' }} level={3}>Buy Now!</Title>
            //                         <Space>
            //                         </Space>
            //                     </div>
            //                 </Button> */}
            //             </Card>


            //         </Col>
            //     </Row>
            // </div>
        )
    }



}

export default AppPhotosSection
