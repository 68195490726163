import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom"
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';
import { motion } from 'framer-motion'
import VideoJS from '../functions/VideoJS';
import ElsaExplainVideo from '../images/ElsaExplainVideo.mp4'
import ElsaOpeningNami from '../images/ElsaOpeningNami.mp4'
import Artboard from '../images/Artboard 1.png'
import Art01 from '../images/Art01.jpg'
import NFTfade6 from '../images/NFTfade6.gif'


const BuySection = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(null);


    const [formState, setFormState] = useState({ name: '', email: '', wallet: '' });

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);
    useEffect(() => {
        if (screenSize > 1368) {
            setActiveMenu(false);
        }
        // else if (screenSize > 1000) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 700) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 500) {
        //     setActiveMenu(true);
        // }
        // else if (screenSize > 300) {
        //     setActiveMenu(true);
        // }
        // else {
        //     setActiveMenu(true);
        // }
    }, [screenSize]);


    const buySection = useRef(null);

    useEffect(() => {
        if (buySection !== state.refs.buySection) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'buySection': buySection } })
        }
    }, [state])



    const container = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 2.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -100,
            // y: 100,
            rotate: 2,
            scale: 0,
            transition: {
                delay: 1,
                type: "spring",
                duration: 5.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                type: "spring",
                duration: 0.5,
                bounce: 0.1,

            }
        }
    }



    // const history = useHistory();


    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }



    async function handleSubmit(event) {
        console.log('event', event)
        event.preventDefault();
        const form = event.target;
        await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                'form-name': form.getAttribute('name'),
                // "form-name": "whitelist",
                ...formState,
            }),
        })
            // return response.json()
            .then((response) => {
                // navigate("/thank-you/")

                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                console.log('response', response)
                alert('You are on the whitelist! Thank you for signing up!')
            })
            .catch((error) => alert(error));
    };

    function handleChange(e) {
        console.log('e', e)
        setFormState((prev) => prev = { ...formState, [e.target.name]: e.target.value.toString() });
    }





    const videoTwo = useRef();

    useEffect(() => {
        if (videoTwo !== state.refs.videoTwo) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'videoTwo': videoTwo } })
        }
    }, [state])



    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: false,
        muted: true,
        controls: true,
        responsive: true,
        fluid: true,
        // height: '100%',
        sources: [{
            // https://drive.google.com/uc?export=download&id=1uqO4IbMx-sGHvYPztIEuZnMrcMCCscgi
            src: "https://player.vimeo.com/video/764874646?h=e79332f1ee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };


    // useEffect(() => {
    //     setTimeout(() => {
    //         var iFrame = document.getElementById('iFrame1');
    //         resizeIFrameToFitContent(iFrame);
    //     }, 2000);
    // }, []);

    // function resizeIFrameToFitContent(iFrame) {

    //     iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
    //     iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    //     iFrame.contentWindow.document.body.video.style.objectFit = 'cover';
    // }

    // window.addEventListener('DOMContentLoaded', function (e) {

    //     var iFrame = document.getElementById('iFrame1');
    //     resizeIFrameToFitContent(iFrame);

    //     // // or, to resize all iframes:
    //     // var iframes = document.querySelectorAll("iframe");
    //     // for( var i = 0; i < iframes.length; i++) {
    //     //     resizeIFrameToFitContent( iframes[i] );
    //     // }
    // });

    // var containerr = document.getElementById('embed_container');
    // var video = document.getElementById('iFrame1');
    // var ratio = 9 / 16; //this is why the 56.25% padding hack exists

    // function resizer() {
    //     var width = parseInt(window.getComputedStyle(containerr)['width'], 10);
    //     var height = (width * ratio);

    //     video.style.width = width + 'px';
    //     video.style.height = height + 'px';
    //     video.style.marginTop = '-3.278%'; //~732px wide, the video border is about 24px thick (24/732)

    //     containerr.style.height = (height * 0.88) + 'px'; //0.88 was the magic number that you needed to shrink the height of the outer container with.
    // }
    // //attach event on resize
    // window.addEventListener('resize', resizer, false);

    // //call function for initial sizing
    // //no need for padding hack since we are setting the height based off of the width * aspect ratio
    // resizer();
    // //container.style.padding = 0;







    function openPaymentWindow() {
        const paymentUrl = "https://pay.nmkr.io/?p=e2ed3be50e5c4a71af5e4afebe433830&c=1";

        // Specify the popup width and height
        const popupWidth = 500;
        const popupHeight = 700;

        // Calculate the center of the screen
        const left = window.top.outerWidth / 2 + window.top.screenX - (popupWidth / 2);
        const top = window.top.outerHeight / 2 + window.top.screenY - (popupHeight / 2);

        const popup = window.open(paymentUrl, "NFT-MAKER PRO Payment Gateway", `popup=1, location=1, width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);

        // Show dim background
        document.body.style = "background: rgba(0, 0, 0, 0.5)";

        // Continuously check whether the popup has been closed
        const backgroundCheck = setInterval(function () {
            if (popup.closed) {
                clearInterval(backgroundCheck);

                console.log("Popup closed");

                // Remove dim background
                document.body.style = "";
            }
        }, 1000);
    }

    return (
        <div ref={buySection} className='formSectionTwoMainDiv'
        // style={{ height: '50vh' }}
        >
            <div className='introSectionTwoSubOne' style={{ height: '100%' }}>
                <div className='introSectionTwoSubTwo' style={{ height: '100%' }} >

                    <Row justify='center' align='middle' style={{ height: '100%', paddingTop: '100px', paddingBottom: '100px' }} >
                        <Col xs={22} sm={22} md={22} lg={20} xl={20}>

                            <motion.h3 className='mintingNowText' style={{ paddingRight: '0px' }} variants={item}>MINTING IS LIVE !   </motion.h3>
                            <Row justify='center' align='top' gutter={[40, 40]} style={{ height: '100%' }} >
                                <Col xs={22} sm={22} md={20} lg={18} xl={16}>
                                    <div className='formContainer' align='left' style={{
                                        borderRadius: '40px', backgroundColor: 'white',
                                        // padding: '40px',
                                        width: '100%'
                                    }}>
                                        <Row>

                                            <Col xs={24} sm={10} md={10} lg={10} xl={10} style={{ padding: '20px' }}>
                                            
                                                <img src={'https://drive.google.com/uc?id=1QCaG8BkeactvanqoeeP6Fo6g6JSVm63n'} alt='art' style={{ size: 'cover', width: '100%', borderRadius: '20px' }} />
                                            </Col>
                                            <Col xs={24} sm={14} md={14} lg={14} xl={14} style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
                                                <Row style={{ width: '100%', paddingBottom: '20px' }}>
                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '20px' }}>
                                                        <Row justify='center' style={{ width: '100%' }}>
                                                            <Col span={12}>
                                                                <h3 style={{ textAlign: 'center' }}>Price</h3>
                                                                <h3 style={{ textAlign: 'center', color: 'gray' }}>$250 </h3>

                                                            </Col>
                                                            <Col span={12}>
                                                                <h3 style={{ textAlign: 'center' }}>Type</h3>
                                                                <h3 style={{ textAlign: 'center', color: 'gray' }}>Random</h3>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Row>
                                                <Row style={{ width: '100%', paddingBottom: '20px' }}>
                                                    <div style={{ width: '100%', borderRadius: '20px', padding: '0px', alignItems: 'center' }}>
                                                        <h3 style={{ textAlign: 'center' }}>Amount</h3>
                                                        <Row gutter={[20,20]} justify='center' align='middle' style={{ width: '100%', margin: '0px' }}>
                                                            <Col span={5}>
                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=1" target="_blank" rel='noreferrer' style={{ width: '100%' }}>

                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>1</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=2" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>2</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=3" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>3</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=4" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>4</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=5" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>5</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=6" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>6</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=7" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>7</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=8" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>8</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            {/* <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=9" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>9</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>

                                                                <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=10" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>10</h3>
                                                                    </div>
                                                                </a>

                                                            </Col> */}




                                                            {/* <Col span={5}>
                                                                <a href="" target="_blank" rel='noreferrer' style={{ width: '100%' }}>

                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>3</h3>
                                                                    </div>
                                                                </a>

                                                            </Col>
                                                            <Col span={5}>
                                                                <a href="" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                                    <div style={{ width: '100%', backgroundColor: '#EFEFEF', borderRadius: '20px', padding: '10px' }}>

                                                                        <h3 style={{ textAlign: 'center', color: 'gray' }}>4</h3>
                                                                    </div>
                                                                </a>

                                                            </Col> */}

                                                        </Row>
                                                    </div>
                                                </Row>

                                                <Row align='bottom' style={{ width: '100%', alignSelf: 'end', marginTop: 'auto' }}>
                                                    <a href="https://pay.nmkr.io/?p=0e9034a4a31043539e845fda03081f6e&c=1" target="_blank" rel='noreferrer' style={{ width: '100%' }}>
                                                        <img src="https://studio.nmkr.io/images/buttons/paybutton_1_1.svg" alt='alt' style={{ width: '100%' }} />
                                                    </a>
                                                    {/* <button className='buyNFTButton' type="submit" style={{ width: '100%' }}   onclick={() => openPaymentWindow()}>MINT NOW</button> */}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>


                                    {/*                                     
                                    <div align='left' style={{ borderRadius: '40px', backgroundColor: 'white', padding: '40px', width: '100%' }}>
                                        <h3> name</h3>
                                        <input />
                                        <br />
                                        <h3> email</h3>
                                        <input />
                                        <br />
                                        <h3> Cardano wallet address</h3>
                                        <input />
                                        <br />
                                        <button>Get on whitelist</button>

                                    </div> */}
                                </Col>





                            </Row>


                        </Col>
                    </Row>
                </div>
            </div >

        </div >
    )
}

export default BuySection