import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';



const WhyCardanoSection1 = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const why1 = useRef();

    useEffect(() => {
        if (why1 !== state?.refs?.why1) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'why1': why1 } })
        }
    }, [state])


    return (
        <div ref={why1} className='why1' style={{
            // height: '80vh',
        }}>
            <Row justify='center' style={{ height: '100%', paddingBottom: '250px' }}>
                <Col  xs={20} sm={18} md={16} lg={16} xl={16} style={{ height: '100%' }}>
                    <Row justify='center' align='middle' style={{ height: '100%', paddingTop: '100px' }}>
                        <Col >
                            <Row justify='center'>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>01 </h3>
                            </Row>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>Low fees</h3>
                                {/* <h3 className='whyh2' style={{ paddingRight: '20px' }}>Low transaction fees</h3> */}
                            </Row>
                            <Row justify='center' style={{paddingBottom: '40px'}}>
                                {/* Compared to other blockchain platforms, Cardano's fees are incredibly cheap. */}
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Compared </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> to </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>  other  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> blockchains,  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> fees  </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> are  </h1>
                                <h1 className='whyh3' style={{ color: '#F9C6CB',  paddingRight: '20px', textAlign: 'left' }}> incredibly  </h1>
                                <h1 className='whyh3' style={{ color: '#F9C6CB', paddingRight: '20px' }}>cheap. </h1>
                                {/* <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Compared to other </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>blockchain platforms, </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Cardano's fees</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> are incredibly</h1>
                                <h1 className='whyh3' style={{ color: '#F9C6CB', paddingRight: '20px' }}>cheap. </h1> */}
                                {/* <h1 className='whyh3'>ON CARDANO</h1> */}
                            </Row>
                            {/* <Row justify='center'>
                                Compared to other blockchain platforms, Cardano's fees are incredibly cheap.
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>This is</h1>
                                <h1 className='whyh3' style={{ color: '#66EDFF', paddingRight: '20px', textAlign: 'left'  }}> convenient </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>for users </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>who want to mint </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> a large number</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> of tokens </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> or for those </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> who want to mint</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>  tokens regularly.</h1>
                                <h1 className='whyh3'>ON CARDANO</h1>
                            </Row> */}

                        </Col>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoSection1


