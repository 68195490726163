import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';



const WhyCardanoSection3 = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const why3 = useRef();

    useEffect(() => {
        if (why3 !== state?.refs?.why3) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'why3': why3 } })
        }
    }, [state])


    return (
        <div ref={why3} className='why3' style={{
            // height: '80vh',
        }}>
            <Row justify='center' style={{ height: '100%', paddingBottom: '250px', paddingTop: '250px'  }}>
                <Col xs={20} sm={18} md={16} lg={16} xl={16} style={{ height: '100%' }}>
                    <Row justify='center' align='middle' style={{ height: '100%' }}>
                        <Col >
                            <Row justify='center'>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>03 </h3>
                            </Row>
                            <Row justify='center' style={{ paddingBottom: '50px' }}>
                                <h3 className='whyh2' style={{ paddingRight: '20px' }}>Security</h3>
                                {/* <h3 className='whyh2' style={{ paddingRight: '20px' }}>Sustainability</h3> */}
                            </Row>
                            <Row justify='center' style={{paddingBottom: '40px'}}>
                                {/* Compared to other blockchain platforms, Cardano's fees are incredibly cheap. */}
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Cardano </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> uses  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>an </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Algorithm  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>called  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> Ouroboros, </h1>
                          
                                {/* <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>Cardano offers </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> a better </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>environmentally</h1>
                                <h1 className='whyh3' style={{ color: '#7AFF87', paddingRight: '20px', textAlign: 'left' }}>sustainable </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>alternative to</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>other blockchains.</h1> */}
                                {/* <h1 className='whyh3'>ON CARDANO</h1> */}
                            </Row>
                          
                            <Row justify='center' style={{paddingBottom: '40px'}}>
                       
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>which  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> has </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> been </h1>
                                 <h1 className='whyh3' style={{color: '#66E3FF',  paddingRight: '20px', textAlign: 'left' }}> mathematically </h1>
                                 <h1 className='whyh3' style={{color: '#66E3FF',  paddingRight: '20px', textAlign: 'left' }}> proven  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}>to </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> be  </h1>
                                 <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left' }}> secure. </h1>
                              
                            </Row>
                          
                          
                            {/* <Row justify='center'>
                                Compared to other blockchain platforms, Cardano's fees are incredibly cheap.
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>This is because</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>Cardano uses </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>the </h1>
                                <h1 className='whyh3' style={{ color: '#66E3FF', paddingRight: '20px', textAlign: 'left'  }}> Proof of Stake </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>(PoS)</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> mechanism</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> that drastically reduces </h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}>  the computational</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> energy consumption</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> needed in generating</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> blocks than</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> the Proof of Work</h1>
                                <h1 className='whyh3' style={{ paddingRight: '20px', textAlign: 'left'  }}> mechanism. </h1>
                                <h1 className='whyh3'>ON CARDANO</h1>
                            </Row> */}

                        </Col>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoSection3


