import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography, Space, Image } from 'antd';

import HeaderSection from '../components/Header';
// import logo from '../logo.svg';
// import Hero from '../components/Hero';
// import SideScrollHero from '../components/SideScrollHero';
// import Roadmap from '../components/Roadmap';
// import Faq from '../components/Faq';
// import Art from '../components/Art';
// import Team from '../components/Team';
// import NFTGateTestSection from '../components/NFTGateTestSection';
// import ContentSection from '../components/ContentSection';
import {
    useGetPhotosFolderQuery
} from '../services/driveApi'
import { BackTop } from 'antd';
import AppHeaderSection from './AppHeaderSection';

const AppPhotosPage = ({ state, dispatch, stateTwo, dispatchTwo }) => {

    const [hasNFT, setHasNFT] = useState(false);


    const [skip1, setSkip1] = useState(true);
    const [driveImages, setDriveImagesArray] = useState([]);
    const { data: allPhotos } = useGetPhotosFolderQuery(driveImages, { skip: skip1 });





    useEffect(() => {
        if (skip1) {
            setSkip1(false)
        }

    }, [])

    useEffect(() => {
        if (!skip1) {
            console.log('allPhotos', allPhotos)
            setSkip1(true)
        }

    }, [allPhotos])

    useEffect(() => {
        console.log('hasNFT changing', hasNFT)


    }, [hasNFT])




    const [visible, setVisible] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    function openGallery(e) {

        // console.log('allPhotos', allPhotos)
        // console.log('e.target', e.target)
        // console.log('e.target.id', e.target.id)
        // console.log('allPhotos.indexOf(e.target.id)', allPhotos.indexOf(e.target.id))
        allPhotos.map((image, index) => {
            if (image.id === e.target.id) {

                // console.log('allPhotos.indexOf(e.target.id)', e.target.id)
                // console.log('found image at index', index)
                setCurrentImageIndex((prev) => index)
                setVisible(true)

            }

        })
        // setVisible(true)
    }

    // useEffect(() => {
    //     console.log('currentImageIndex', currentImageIndex)

    //     // setVisible(true)


    // }, [currentImageIndex])





    if (stateTwo?.hasNFT === true) {

        return (
            <div
                style={{ backgroundColor: '#141414' }}>
                <BackTop />
                <AppHeaderSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />

                {/* <SideScrollHero /> */}
                {/* <Hero state={state} dispatch={dispatch} /> */}

                {/* <ContentSection state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} /> */}






                <div className='NFTGateSection' style={{
                }}>
                    <Row justify='center' className='NFTGateSectionSubDiv' style={{
                        height: '100%',
                        //  paddingTop: '100px', 
                        paddingBottom: '100px'
                    }}>

                        <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ height: '100%' }}>
                            <br />
                            <br />
                            <p className='introSectionText' style={{ textAlign: 'left', color: '#F9C6CB', marginBottom: '0px' }}> Private Photo's  </p>
                            <br />
                            <Row justify='start' gutter={[20, 20]}>


                                <div
                                    style={{
                                        display: 'none',
                                    }}
                                >

                                    <Image.PreviewGroup
                                        preview={{
                                            visible,
                                            onVisibleChange: (vis) => setVisible(vis),
                                            current: currentImageIndex,
                                        }}
                                    >
                                        {allPhotos?.map((image, index) => {
                                            // console.log('string length', image.thumbnailLink.slice(0, (image.thumbnailLink.length - 5)))
                                            return (
                                                <Image
                                                    key={index}
                                                    // referrerpolicy="no-referrer"

                                                    // width={'100%'}
                                                    // height={'100%'}
                                                    // src={image.thumbnailLink.slice(0, (image.thumbnailLink.length - 5))}
                                                    src={`https://drive.google.com/uc?export=download&id=${image.id}&s`}
                                                />
                                            )
                                        }
                                        )}
                                    </Image.PreviewGroup>
                                </div>





                                {/* <Col id={20} xs={24} sm={12} md={6} lg={6} xl={6}>


                                    <PhotoWidget photoId={'1louTK3fTztWyHnQIeXlQt1jVbBUJTl6U'} />
                                   
                                </Col> */}
                                {allPhotos?.map((image, index) => {
                                    return (
                                        <Col key={index} xs={24} sm={12} md={8} lg={8} xl={8}>
                                            {/* <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p> */}



                                            <div

                                                // style="padding:56.25% 0 0 0;position:relative;"
                                                style={{ padding: '56.25% 0 0 0', position: 'relative' }}

                                            >
                                                <img
                                                    className='imageElsa'

                                                    src={image.thumbnailLink}
                                                    // src={image.thumbnailLink.slice(0, (image.thumbnailLink.length - 5))+'=s520'}
                                                    referrerpolicy="no-referrer"
                                                    // src={`https://drive.google.com/uc?export=download&id=${image.id}&s`}
                                                    id={image.id}
                                                    // width={'100%'}
                                                    // height={'100%'}
                                                    // style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover' }}
                                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", objectFit: 'cover' }}
                                                    onClick={(e) => {
                                                        // setVisible(true)
                                                        openGallery(e)

                                                    }}
                                                    alt='asdsfa'
                                                >

                                                </img>
                                            </div>
                                            {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
                                        </Col>)
                                })}
                                {/* <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>



                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <img
                                        src='https://drive.google.com/uc?export=download&id=1dkSbTnx5gIxN8SP_cXdy_OGKdAEeFsgh'
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", objectFit: 'cover' }}
                                        alt='asdsfa'
                                    >

                                    </img>
                                </div>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/766471506?h=990bf633b9"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="elsaStreamHeroVideo"
                                    >

                                    </iframe>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/766471506?h=990bf633b9"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="elsaStreamHeroVideo"
                                    >

                                    </iframe>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <p className='introSectionText' style={{ color: '#F9C6CB' }}> NFT Gated content  </p>
                                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

                                <div
                                    // style="padding:56.25% 0 0 0;position:relative;"
                                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                >
                                    <iframe
                                        src="https://player.vimeo.com/video/766471506?h=990bf633b9"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="elsaStreamHeroVideo"
                                    >

                                    </iframe>
                                </div>
                            </Col> */}

                            </Row>
                            <br />
                            <br />
                            {/* <Link to={`${url}photos`}>
                                <p
                                    //  className='introSectionText'
                                    style={{ textAlign: 'middle', color: '#F9C6CB' }}
                                >
                                    View all
                                </p>

                            </Link> */}

                        </Col>

                    </Row>
                </div>

            </div>
        )
    } else {
        return (
            null
        )

    }
}

export default AppPhotosPage