import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import VideoJS from '../functions/VideoJS';
const { Paragraph, Text } = Typography;

const DescriptionComponent = ({ tutorial }) => {
    const [ellipsis, setEllipsis] = useState(true);

    return (
        <div>

            <Row justify="start">
                <Paragraph
                style={{textAlign:"start"}}
                    ellipsis={
                        ellipsis
                            ? {
                                rows: 2,
                                // expandable: true,
                                // symbol: 'more',
                            }
                            : false
                    }
                >
                    <h6 style={{ fontSize: '20px' }}>{tutorial?.description}</h6>

                </Paragraph>
            </Row>
            <Row justify="center">
                {ellipsis ?
                    <DownOutlined
                        style={{ fontSize: "24px" }}
                        onClick={() => {
                            setEllipsis(!ellipsis);
                        }} />
                    :
                    <UpOutlined style={{ fontSize: "24px" }}
                        onClick={() => {
                            setEllipsis(!ellipsis);
                        }} />
                }
            </Row>
        </div>
    )
}

export default DescriptionComponent