import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography, Space } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import VideoJS from '../functions/VideoJS';
// import Rick from '../images/Rick.mp4';
// import holoman from '../images/hologif.gif';
// import TimerBar from './TimerBar';

import { useGetAudioFolderQuery } from '../services/driveApi';
import { CaretRightOutlined } from '@ant-design/icons';
import AudioPlayer from './AudioPlayer';

const { Title, Text } = Typography;



const AppAudioSection = ({ state, dispatch, stateTwo, dispatchTwo }) => {

    const [hasNFT, setHasNFT] = useState(false);
    const { path, url } = useRouteMatch()


    const [skip1, setSkip1] = useState(true);
    const [vimeoSheetsArray, setVimeoSheetsArray] = useState([]);
    const { data: allVideos } = useGetAudioFolderQuery(vimeoSheetsArray, { skip: skip1 });

    useEffect(() => {
        if (skip1) {
            setSkip1(false)
        }

    }, [])

    useEffect(() => {
        if (!skip1) {
            console.log('allVideos', allVideos)
            setSkip1(true)
        }

    }, [allVideos])


    // const NFTGateTest = useRef();

    // useEffect(() => {
    //     if (NFTGateTest !== state?.refs?.NFTGateTest) {
    //         dispatch({ type: 'setRefs', payload: { ...state?.refs, 'NFTGateTest': NFTGateTest } })
    //     }
    // }, [state])


    // useEffect(() => {

    //     let userHasNFT = false;

    //     if (stateTwo?.Utxos?.length) {
    //         console.log('Utxos: ', stateTwo?.Utxos)

    //         stateTwo?.Utxos?.map((Utxo) => {
    //             console.log('Utxo in map: ', Utxo)
    //             console.log('Utxo . multiAssetStr : ', Utxo?.multiAssetStr)

    //             if (Utxo?.multiAssetStr?.includes('NMKR')) {
    //                 console.log('Utxo includes NMKR Yay!')
    //                 console.log('set hasNFT to true')
    //                 userHasNFT = true
    //                 if (hasNFT === false) {
    //                     // setHasNFT( true)
    //                 }

    //             }

    //         })

    //         // if (stateTwo?.Utxos?.inlcudes('NMKR')) {
    //         //     console.log(' aray includes !! Utxos: ', stateTwo?.Utxos)

    //         // }
    //     }

    //     if (hasNFT !== userHasNFT) {
    //         console.log(' hasNFT !== userHasNFT, setHasNFT( userHasNFT ) ', userHasNFT)
    //         setHasNFT(userHasNFT)
    //     }

    // }, [stateTwo.Utxos])


    useEffect(() => {
        console.log('hasNFT changing', hasNFT)


    }, [hasNFT])




    if (stateTwo?.hasNFT === true) {

        return (

            <div className='NFTGateSection' style={{
            }}>
                <Row justify='center' className='NFTGateSectionSubDiv' style={{
                    height: '100%',
                    //  paddingTop: '100px', 
                    paddingBottom: '100px'
                }}>
                    <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ height: '100%' }}>
                        <p className='introSectionText' style={{ textAlign: 'left', color: '#F9C6CB', marginBottom: '0px' }}> Private Audio  </p>
                        <br />

                    </Col>

                    <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ height: '100%' }}>
                        <Row justify='center' gutter={[20, 20]}>

                            {allVideos?.slice(0, 4).map((video, index) => {
                                return (

                                    <Col key={index} xs={24} sm={18} md={13} lg={13} xl={13} 
                                    style={{paddingBottom: '50px'}}
                                    >
                                        <AudioPlayer name={video?.name?.slice(0, video.name?.length - 4)} url={video.webContentLink.slice(0, video.webContentLink?.length - 9)} />

                                        {/* <Row
                                            gutter={[10, 10]}
                                            justify='space-between'
                                            align='middle'
                                            style={{ height: '70px', backgroundColor: 'white', borderRadius: '100px' }}
                                        >
                                            <Col
                                                align='middle'
                                                className='audioPlayButton'
                                                style={{ marginLeft: '10px', width: '90px', height: '60px', backgroundColor: '#FFC0CF', borderRadius: '60px' }}
                                            >
                                                <Row align='middle' justify='center' style={{ height: '100%', }}>
                                                    <Col>
                                                        <CaretRightOutlined style={{ fontSize: 50, color: 'white' }} color='white' />
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col flex="auto" style={{ marginLeft: '10px', marginRight: '20px' }}>
                                                <div className='audioProgressBar' style={{ height: '20px', backgroundColor: '#D9D9D9', borderRadius: '20px' }}>

                                                    <div style={{ height: '100%', backgroundColor: 'pink', borderRadius: '20px', width: '80%' }}>

                                                    </div>
                                                </div>
                                            </Col>

                                        </Row> */}

                                        {/* <div
                                            // style="padding:56.25% 0 0 0;position:relative;"
                                            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                                        >
                                            <iframe

                                                src={video.webContentLink.slice(0, video.webContentLink?.length - 9)}
                                                // src="https://player.vimeo.com/video/765825293?h=0533c99cfb"
                                                // src="https://player.vimeo.com/video/766471506?h=990bf633b9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullScreen
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%" }}
                                                // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                title="elsaStreamHeroVideo"
                                            >

                                            </iframe>
                                        </div> */}
                                    </Col>
                                )
                            })}



                        </Row>
                        <br />
                        <br />
                        <Link to={`${url}/audio`} >
                            <p
                                //  className='introSectionText'
                                style={{ textAlign: 'middle', color: '#F9C6CB' }}
                            >
                                View all
                            </p>

                        </Link>



                    </Col>

                </Row>
            </div>
        )


    } else {
        return (
            null
            // <div>
            //     <Row justify='center' className='NFTGateSectionSubDiv' style={{
            //         height: '100%',
            //         //  paddingTop: '100px', 
            //         paddingBottom: '100px'
            //     }}>
            //         <Col xs={22} sm={12} md={6} lg={6} xl={6}>

            //             <Card
            //                 hoverable
            //                 style={{
            //                     // width: artSize,
            //                     borderRadius: 40,
            //                 }}
            //                 cover={<img alt="example" src={holoman} style={{
            //                     borderTopLeftRadius: 40,
            //                     borderTopRightRadius: 40,
            //                 }} />}
            //                 actions={[
            //                 ]}
            //             >
            //                 <h3 className='buyNFTButtonText'  style={{ color: 'black' }}>
            //                     holoman
            //                 </h3>
            //                 <br />
            //                 <button className='buyNFTButton'>
            //                     <h3 className='buyNFTButtonText'  >
            //                         Get access
            //                     </h3>
            //                 </button >


            //                 {/* <Button justify='center' align='middle' size='large' block={true}
            //                 // onClick={() => window.open(image.buyLink)} target="_blank" style={{ borderRadius: 60, padding: 10, height: 'auto' }}
            //                 >
            //                     <div>
            //                         <h3 className='buyNFTButtonText' style={{ color: 'black' }} >
            //                             Get access
            //                         </h3>
            //                         <Title style={{ paddingTop: 4, color: 'black' }} level={3}>Buy Now!</Title>
            //                         <Space>
            //                         </Space>
            //                     </div>
            //                 </Button> */}
            //             </Card>


            //         </Col>
            //     </Row>
            // </div>
        )
    }



}

export default AppAudioSection
