import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import Hero from '../components/Hero';
import WhyCardanoBottom from '../WhyCardano/WhyCardanoBottom';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';


import { BackTop } from 'antd';

const Imprint = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    return (
        <div style={{ width: '100%' }}>
            <BackTop />

            {/* <Hero state={state} dispatch={dispatch} /> */}
            <Row style={{ width: '100%', height: '150px', backgroundColor: '#F9C6CB' }}
                // display='flex'
                // justify-content='center'
                justify='center'
                align='middle'>
                <Col>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'white', margin: '0px' }}>Elsa Jean </h3>
                </Col>
            </Row>
            <br />
            <br />
            <br />


            <h3 className='introSectionText' style={{ paddingRight: '0px', color: 'black', margin: '0px' }}>Imprint </h3>

            <br />
            <br />
            {/* <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}>1. </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}> Privacy Policy </h3>

                </Col>

            </Row>
            <br />
            <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px' }}> </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <p>Your privacy is important to ADA Ninjaz (86 653 529 287). ADA Ninjaz respect your privacy and are committed to protecting your personal information.</p>
                    <p>ADA Ninjaz will collect, hold and use your personal information in accordance with this Privacy Policy.</p>
                    <p>In this Privacy Policy:</p>
                    <ul>
                        <li>depending on the context, &ldquo;we&rdquo;, &ldquo;our&rdquo; and &ldquo;us&rdquo; refer to ADA Ninjaz</li>
                    </ul>
                    <p>If you do not provide us with the information that we request, then we may not be able to provide you with our products and services.</p>
                </Col>

            </Row> */}

            <br />
            <br />

            {/* <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}>1. </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}> Privacy Policy </h3>

                </Col>

            </Row> */}
            <br />
            <Row justify='center'>
                {/* <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px' }}> </h3>
                </Col> */}
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <p><span>Rudolf Budja</span><br /><span>Wiener-Philharmoniker-Gasse 3</span><br /><span>5020 Salzburg</span><br /><span>&Ouml;sterreich</span><br /><span>budja@me.com</span></p>
                </Col>

            </Row>



            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <WhyCardanoBottom state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />


        </div>
    )
}

export default Imprint