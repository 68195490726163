import { Carousel, Row, Col, Card, Typography, Button, Space } from 'antd';
import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { HomeOutlined, MoneyCollectOutlined, BulbOutlined, FundOutlined, MenuOutlined, DownOutlined, LockOutlined } from '@ant-design/icons/lib/icons';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';

// Import Swiper styles
import 'swiper/css/bundle';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
// import required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { motion } from 'framer-motion'

import Wireframe1 from '../images/Wireframe1.png'
import Wireframe2 from '../images/Wireframe2.png'
import Wireframe3 from '../images/Wireframe3.png'
import Jean01 from '../images/LockedBlur03.jpg'
import Jean02 from '../images/LockedBlur02.jpg'
import Jean03 from '../images/LockedBlur01.jpg'


const { Title, Text } = Typography;

const contentStyle = {
    height: '400px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const { Meta } = Card;


const LockedContent = ({ state, dispatch }) => {


    const [activeArt, setActiveArt] = useState(1);
    const [artSize, setArtSize] = useState('20vw');
    const [screenSize, setScreenSize] = useState(null);

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        if (screenSize < 480) {
            setActiveArt(2);
            setArtSize('80vw');
        } else if (screenSize < 900) {
            setActiveArt(2);
            setArtSize('60vw');
        } else if (screenSize < 1200) {
            setActiveArt(3);
            setArtSize('40vw');
        } else if (screenSize < 1500) {
            setActiveArt(4);
            setArtSize('20vw');
        } else if (screenSize > 1500) {
            setActiveArt(5);
            setArtSize('20vw');
        }
    }, [screenSize]);

    const lockedContent = useRef();

    useEffect(() => {
        if (lockedContent !== state.refs.lockedContent) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'lockedContent': lockedContent } })
        }
    }, [state])


    const scrollToSection = (elementRef) => {
        // console.log('elementRef', elementRef)
        if (elementRef?.current) {

            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: "smooth",
            })
        }
    }



    const images = [
        {
            original: Jean01,
            name: "Lifetime access to Elsa's private community.",
            buyLink: "https://pay.nmkr.io/?p=e2ed3be50e5c4a71af5e4afebe433830&n=b43c38be93c845c7b22986ceadc5fad8",
            id: 1
        },
        {
            original: Jean02,
            name: "Intimate one on one calls & private chat rooms ",
            buyLink: "https://pay.nmkr.io/?p=e2ed3be50e5c4a71af5e4afebe433830&n=17ae8093c869492cae18298bfe1bbfca",
            id: 2
        },
        {
            original: Jean03,
            name: "Never before seen X-rated content and behind the scenes material. Updated every week!",
            buyLink: "https://pay.nmkr.io/?p=e2ed3be50e5c4a71af5e4afebe433830&n=b0e9f4c89707473f9e55ae84aa738bf3",
            id: 3
        }
    ];


    const container = {
        hidden: {
            // opacity: 0,
            transition: {
                duration: 2.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            // opacity: 1,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            // x: -800,
            y: 100,
            rotate: 2,
            scale: 0,
            transition: {
                delay: 1,
                type: "spring",
                duration: 5.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            // x: 0,
            y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                type: "spring",
                duration: 0.5,
                bounce: 0.1,

            }
        }
    }




    return (
        <>
            <div ref={lockedContent} className='LockedSection' >
                <div className='LockedSectionSubDiv'>


                    <Row justify='center' style={{ paddingTop: '100px', paddingBottom: '50px', }}>
                        <Col xs={22} sm={22} md={22} lg={22} xl={20}>
                            {/* <h2 className='lockedContentTitle'>Locked Content</h2> */}

                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                            >

                                <Row
                                    justify='center'
                                    align='middle'
                                    gutter={[50, 50]}
                                    style={{ paddingBottom: '100px' }}
                                // style={{ height: '100%' }}
                                >
                                    <Col xs={22} sm={8} md={6} lg={6} xl={6}>
                                        <motion.div variants={item}>


                                            <img alt="example" src={images[0].original} className='lockedContentImage' style={{
                                                // height: artSize,
                                                // width: artSize,
                                                // height: '100%',
                                                // height: artSize/2,
                                                // backgroundRepeat: 'no-repeat',
                                                // backgroundSize: 'cover',
                                                // objectFit: 'cover',
                                                // paddingBottom: '40px',
                                                // overflow: 'hidden',
                                                // aspectRatio: 2 / 5,

                                                // borderTopLeftRadius: 40,
                                                // borderTopRightRadius: 40,
                                            }} />
                                        </motion.div>

                                    </Col>
                                    <Col xs={22} sm={12} md={12} lg={12} xl={12}>
                                        <motion.h3 className='lockedSectionCardTitle' variants={item}>{images[0].name}</motion.h3>

                                    </Col>
                                </Row>
                            </motion.div>



                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                            >

                                <Row justify='center' align='middle' gutter={[50, 50]} style={{ paddingBottom: '100px' }}>
                                    <Col xs={22} sm={8} md={6} lg={6} xl={6}>
                                        <motion.div variants={item}>


                                            <img alt="example" src={images[1].original} className='lockedContentImage' style={{
                                                // height: artSize,
                                                // width: artSize,
                                                // height: '100%',
                                                // height: artSize/2,
                                                // backgroundRepeat: 'no-repeat',
                                                // backgroundSize: 'cover',
                                                // objectFit: 'cover',
                                                // paddingBottom: '40px',
                                                // overflow: 'hidden',
                                                // aspectRatio: 2 / 5,

                                                // borderTopLeftRadius: 40,
                                                // borderTopRightRadius: 40,
                                            }} />
                                        </motion.div>
                                    </Col>
                                    <Col xs={22} sm={12} md={12} lg={12} xl={12}>
                                        <motion.h3 className='lockedSectionCardTitle' variants={item} >{images[1].name}</motion.h3>

                                    </Col>
                                </Row>

                            </motion.div>

                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                exit={{ opacity: 0, y: -200 }}
                                viewport={{ once: false, amount: 0 }}
                            >


                                <Row justify='center' align='middle' gutter={[50, 50]} style={{ paddingBottom: '100px' }}>
                                    <Col xs={22} sm={8} md={6} lg={6} xl={6}>
                                        <motion.div variants={item}>


                                            <img alt="example" src={images[2].original} className='lockedContentImage' style={{
                                                // height: artSize,
                                                // width: artSize,
                                                // height: '100%',
                                                // height: artSize/2,
                                                // backgroundRepeat: 'no-repeat',
                                                // backgroundSize: 'cover',
                                                // objectFit: 'cover',
                                                // paddingBottom: '40px',
                                                // overflow: 'hidden',
                                                // aspectRatio: 2 / 5,

                                                // borderTopLeftRadius: 40,
                                                // borderTopRightRadius: 40,
                                            }} />
                                        </motion.div>
                                    </Col>
                                    <Col xs={22} sm={12} md={12} lg={12} xl={12}>
                                        <motion.h3 className='lockedSectionCardTitle'  variants={item}>{images[2].name}</motion.h3>

                                    </Col>
                                </Row>


                            </motion.div>



                            {/* <Row gutter={50} justify='center'>
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <div className='lockedContentImageWrapper' style={{
                                        // width: artSize,
                                        // width: artSize,
                                        // aspectRatio: 2 / 5,
                                        // borderRadius: 40,
                                    }}>
                                        <img alt="example" src={images[0].original} className='lockedContentImage' style={{
                                            // height: artSize,
                                            // width: artSize,
                                            // height: '100%',
                                            // height: artSize/2,
                                            // backgroundRepeat: 'no-repeat',
                                            // backgroundSize: 'cover',
                                            // objectFit: 'cover',
                                            // paddingBottom: '40px',
                                            // overflow: 'hidden',
                                            // aspectRatio: 2 / 5,

                                            // borderTopLeftRadius: 40,
                                            // borderTopRightRadius: 40,
                                        }} />
                                    </div>
                                    <br />
                                    <br />
                                    <h3 className='lockedSectionCardTitle' >{images[0].name}</h3>
                                    <br />
                                </Col>
                                <Col xs={22} sm={8} md={8} lg={8} xl={8}>
                                    <div className='lockedContentImageWrapper' style={{
                                        // width: artSize,
                                        // width: artSize,
                                        // aspectRatio: 2 / 5,
                                        // borderRadius: 40,
                                    }}>
                                        <img alt="example" src={images[1].original} className='lockedContentImage' style={{
                                            // height: artSize,
                                            // width: artSize,
                                            // height: '100%',
                                            // height: artSize/2,
                                            // backgroundRepeat: 'no-repeat',
                                            // backgroundSize: 'cover',
                                            // objectFit: 'cover',
                                            // paddingBottom: '40px',
                                            // overflow: 'hidden',
                                            // aspectRatio: 2 / 5,

                                            // borderTopLeftRadius: 40,
                                            // borderTopRightRadius: 40,
                                        }} />
                                    </div>
                                    <br />
                                    <br />
                                    <h3 className='lockedSectionCardTitle' >{images[1].name}</h3>
                                    <br />
                                </Col>
                                <Col xs={22} sm={8} md={8} lg={8} xl={8}>
                                    <div className='lockedContentImageWrapper' style={{
                                        // width: artSize,
                                        // width: artSize,
                                        // aspectRatio: 2 / 5,
                                        // borderRadius: 40,
                                    }}>
                                        <img alt="example" src={images[2].original} className='lockedContentImage' style={{
                                            // height: artSize,
                                            // width: artSize,
                                            // height: '100%',
                                            // height: artSize/2,
                                            // backgroundRepeat: 'no-repeat',
                                            // backgroundSize: 'cover',
                                            // objectFit: 'cover',
                                            // paddingBottom: '40px',
                                            // overflow: 'hidden',
                                            // aspectRatio: 2 / 5,

                                            // borderTopLeftRadius: 40,
                                            // borderTopRightRadius: 40,
                                        }} />
                                    </div>
                                    <br />
                                    <br />
                                    <h3 className='lockedSectionCardTitle' >{images[2].name}</h3>
                                    <br />

                                </Col>
                            </Row> */}


                            {/* <div className='lockedContentButton' onClick={() => scrollToSection(state.refs.buyNFT)}> Unlock with NFT </div> */}




                            {/* <Row justify='center'>
                                <Col span={24}>

                                    <Swiper
                                        slidesPerView={"auto"}
                                        centeredSlides={true}
                                        spaceBetween={30}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        // modules={[Pagination]}
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                        navigation={true}
                                        // navigation={{
                                        //     prevButton: ".swiper-button-next",
                                        //     nextEl: ".swiper-button-prev",
                                        // }}
                                        // nextButton={".swiper-button-next"}
                                        // prevButton=".swiper-button-prev"
                                        className="mySwiper"
                                        style={{ zIndex: 800 }}
                                        grabCursor={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        observer='true'
                                        observeParents='true'
                                        maxBackfaceHiddenSlides={10}

                                    >
                                        {images.map((image) => (


                                            <SwiperSlide key={image.id} style={{ paddingLeft: 0, paddingTop: 60, paddingBottom: 0, width: 600, zIndex: 999 }}>
                                                <div className='lockedContentImageWrapper' style={{
                                                    // width: artSize,
                                                    // width: artSize,
                                                    // aspectRatio: 2 / 5,
                                                    // borderRadius: 40,
                                                }}>
                                                    <img alt="example" src={image.original} className='lockedContentImage' style={{
                                                        // height: artSize,
                                                        // width: artSize,
                                                        // height: '100%',
                                                        // height: artSize/2,
                                                        // backgroundRepeat: 'no-repeat',
                                                        // backgroundSize: 'cover',
                                                        // objectFit: 'cover',
                                                        // paddingBottom: '40px',
                                                        // overflow: 'hidden',
                                                        // aspectRatio: 2 / 5,

                                                        // borderTopLeftRadius: 40,
                                                        // borderTopRightRadius: 40,
                                                    }} />
                                                </div>
                                                <br />
                                                <br />
                                                <h3 className='lockedSectionCardTitle' >{image.name}</h3>
                                                <br />



                                            </SwiperSlide>


                                        ))}


                                    </Swiper>
                                    <div className='lockedContentButton'> Unlock with NFT </div> 

                                    <br />
                                    <br />
                                  
                                </Col>
                            </Row> */}


                        </Col>


                    </Row >
                </div>
            </div >
        </>
    )
}

export default LockedContent