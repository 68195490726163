import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import Hero from '../components/Hero';
import WhyCardanoBottom from '../WhyCardano/WhyCardanoBottom';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';


import { BackTop } from 'antd';

const Legal = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    return (
        <div style={{ width: '100%' }}>
            <BackTop />

            {/* <Hero state={state} dispatch={dispatch} /> */}
            <Row style={{ width: '100%', height: '150px', backgroundColor: '#F9C6CB' }}
                // display='flex'
                // justify-content='center'
                justify='center'
                align='middle'>
                <Col>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'white', margin: '0px' }}>Elsa Jean </h3>
                </Col>
            </Row>
            <br />
            <br />
            <br />


            <h3 className='introSectionText' style={{ paddingRight: '0px', color: 'black', margin: '0px' }}>Legal </h3>

            <br />
            <br />
            {/* <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}>1. </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}> Privacy Policy </h3>

                </Col>

            </Row>
            <br />
            <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px' }}> </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <p>Your privacy is important to ADA Ninjaz (86 653 529 287). ADA Ninjaz respect your privacy and are committed to protecting your personal information.</p>
                    <p>ADA Ninjaz will collect, hold and use your personal information in accordance with this Privacy Policy.</p>
                    <p>In this Privacy Policy:</p>
                    <ul>
                        <li>depending on the context, &ldquo;we&rdquo;, &ldquo;our&rdquo; and &ldquo;us&rdquo; refer to ADA Ninjaz</li>
                    </ul>
                    <p>If you do not provide us with the information that we request, then we may not be able to provide you with our products and services.</p>
                </Col>

            </Row> */}

            <br />
            <br />
{/* 
            <Row justify='center'>
                <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}>1. </h3>
                </Col>
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px', fontSize: '30px' }}> Privacy Policy </h3>

                </Col>

            </Row> */}
            <br />
            <Row justify='center'>
                {/* <Col span={1}>
                    <h3 className='introSectionText' style={{ paddingRight: '20px', color: 'black', margin: '0px' }}> </h3>
                </Col> */}
                <Col xs={20} sm={20} md={16} lg={12} xl={12}>
                    <p><span>Schedule 1 &ndash; ADA NINJAZ PRIVACY POLICY</span></p>
                    <p><span>​</span></p>
                    <p><span>ADA NINJAZ Privacy Policy</span></p>
                    <p><span>​</span></p>
                    <p>Your privacy is important to ADA Ninjaz (86 653 529 287). ADA Ninjaz respect your privacy and are committed to protecting your personal information.</p>
                    <p>ADA Ninjaz will collect, hold and use your personal information in accordance with this Privacy Policy.</p>
                    <p>In this Privacy Policy:</p>
                    <ul>
                        <li>depending on the context, &ldquo;we&rdquo;, &ldquo;our&rdquo; and &ldquo;us&rdquo; refer to ADA Ninjaz</li>
                    </ul>
                    <p>If you do not provide us with the information that we request, then we may not be able to provide you with our products and services.</p>
                    <p>​</p>
                    <p><span>What is personal information?</span></p>
                    <p><span>​</span></p>
                    <p>Personal information is information or an opinion, whether true or not, and whether recorded in material form or not, about an individual whose identity is reasonably apparent, or can be reasonably ascertained, from the information or opinion. Examples can include your name, postal address, telephone number and email address.</p>
                    <p>We collect personal information to properly and efficiently carry out our respective functions, including to provide you with your requested products and services, and to facilitate the provision of marketing and promotion services that may be of interest to you.</p>
                    <p>The personal information collected and held about you by ADA Ninjaz will vary depending on the circumstances of collection and may include, but is not limited to, the following:</p>
                    <ul>
                        <li>your name, address, email address, date of birth, gender, occupation, contact information;</li>
                        <li>background checks including police checks and working with children checked if you apply for employment, coaching and volunteer positions in ADA Ninjaz</li>
                        <li>other information provided voluntarily by you, for example in response to surveys or competitions.</li>
                    </ul>
                    <p><span>How do we collect your personal information?</span></p>
                    <p>We collect personal information about you in a number of ways, including:</p>
                    <ul>
                        <li>directly from you, for example, when you provide information to us by phone, email, in an application form or competition entry form, when you subscribe to ADA Ninjaz newsletters, submit information through or access our websites, during interviews for the content we create.</li>
                        <li>from third parties, such as third party service providers, and other commercial data sources and data providers. We may collect personal information regarding a child from the parent or other responsible person associated with that child.</li>
                    </ul>
                    <p>We may also receive information about you from third parties in relation to other websites. For example, you may choose to participate in a third party application or feature as part of our services (such as logging in through Facebook Connect or Google+ or otherwise linking to the services from another website or interactive service) or on a third party website or service (such as a Facebook application or a similar application or feature) through which you allow us to collect (or the third party to share) information about you, including personal information.</p>
                    <p>When you choose to participate, you may be opting to link your activity on ADA Ninjaz with that third party website or service, which may then collect information about your visits to ADA Ninjaz websites and may publish that activity as you request to your &ldquo;profile&rdquo; or similar feature with that third party (such as if you choose to share content you find on ADA Ninjaz with your &ldquo;connections&rdquo; on the third party&rsquo;s website or service).</p>
                    <p>The information we collect is subject to this Privacy Policy. However, the information collected and stored by the third party remains subject to the third party&rsquo;s privacy practices, including whether the third party continues to share information with us or with other third parties, the types of information shared, and your choices with regard to what is visible to others on that third party&rsquo;s website or service. The third party may allow you to remove the application or feature, in which case we will no longer collect information about you through the application or feature, but we may retain the information previously collected. In addition, we may receive information about you if other users of a third-party website or service give us access to their profiles and you are one of their &ldquo;connections&rdquo; or information about you is otherwise accessible through your &ldquo;connections'&rdquo; web page, profile page, or similar page on a social networking or other third-party website or interactive service.</p>
                    <p><span>​</span></p>
                    <p><span>Providing information</span></p>
                    <p><span>​</span></p>
                    <p>If you do not provide some or all of the information that we request from you, this may affect our ability to communicate with you or provide the requested products or services.</p>
                    <p>​</p>
                    <p>By not providing requested information, you may jeopardise your ability to attend events, participate in programs or competitions or apply for employment or volunteer positions with us. If it is impracticable for us to deal with you as a result of you not providing the requested information or consent, we may refuse to do so.</p>
                    <p>​</p>
                    <p>If you provide information to us about any person other than yourself, you must ensure that they understand how their information will be collected, used and disclosed as set out in this Privacy Policy and that you are authorised to disclose it to us, and to consent to its use on their behalf, before doing so.</p>
                    <p>​</p>
                    <p><span>Information storage and protection</span></p>
                    <p><span>​</span></p>
                    <p>We store information in different ways, including in paper and electronic form. When your information is provided to us, the information may be combined or linked with other information held about you.</p>
                    <p>​</p>
                    <p>Security of personal information is important to us. We have taken steps to protect the information we hold from misuse, loss, unauthorised access, modification or disclosure. Some of the security measures we use include strict confidentiality requirements of our respective employees, volunteers, service providers, security measures for system access and security measures for our website.</p>
                    <p><span>How do we use your personal information?</span></p>
                    <p><span>​</span></p>
                    <p>ADA Ninjaz may use your personal information for the primary purpose for which it was collected and secondary purposes related to the primary purpose. Such primary and secondary purposes may include, without limitation, use of your personal information to:</p>
                    <ul>
                        <li>Administer your request for newsletter subscription</li>
                        <li>Provide the services and/or communications you request from us;</li>
                        <li>Carry out market research and surveys;</li>
                        <li>Track and analyse activity on our websites;</li>
                        <li>Understand and respect your preferences and to provide details of relevant content where you have agreed to receive them;</li>
                        <li>Verify your identity;</li>
                        <li>Where applicable as part of an engagement with ADA Ninjaz, complete background checks and/or working with children checks;</li>
                        <li>Develop, run, administer and market competitions, programs, activities and other Events relating to NFTs and Manga;</li>
                        <li>Market products, services, merchandise and special offers made available by ADA Ninjaz or our respective corporate partners, licensees, suppliers and sponsors;</li>
                        <li>Administer and manage our respective websites and provide you with access to those websites;</li>
                        <li>Keep you informed of news and information relating to NFTs and Manga, including by distributing newsletters, publications and other communication via various mediums;</li>
                        <li>Research, develop, run, administer and market new competitions, programs, activities and other events relating to NFTs and Manga; and</li>
                        <li>Research and develop new products, services and merchandise relating to NFT and Manga</li>
                    </ul>
                    <p>​</p>
                    <p><span>Health and sensitive information</span></p>
                    <p>If it is reasonably necessary in the circumstances, or you have elected to provide it, ADA Ninjaztmay also collect sensitive information (which is a type of personal information) such as:</p>
                    <ul>
                        <li>information about your health and medical history; or</li>
                        <li>other sensitive information about you (for example, information about your racial or ethnic origin or that of your parents).</li>
                        <li>Sensitive information is afforded a higher level of privacy protection than other personal information. Where you provide sensitive information to us, you also provide consent to us collecting it in accordance with this Privacy Policy, unless you tell us otherwise.</li>
                    </ul>
                    <p>ADA Ninjaz may use health information about you for insurance purposes and/or to ensure that our content reflects actual social environment. If you do not consent to the collection of your health and sensitive information (and we are not otherwise permitted under law to collect that information) please do not provide your health and sensitive information when prompted to do so.</p>
                    <p>In addition, we may use de-identified health information and other sensitive information to carry out research, to plan events and activities or to prepare submissions to government or a government body. De-identified information is information which has been aggregated or otherwise de-identified so that it cannot be used to identify you or any other individual.</p>
                    <p><span>When do we disclose your personal information?</span></p>
                    <p>We may disclose your personal information to:</p>
                    <ul>
                        <li>ADA Ninjaz</li>
                        <li>Organisations (other than ADA Ninjaz) involved in NFTs, cryptocurrency &amp; manga/anime;</li>
                        <li>Companies that we have engaged or used to carry out functions and activities on our behalf, including companies involved in:</li>
                    </ul>
                    <ul>
                        <li>advertising and marketing our products, services, merchandise and special offers (including social media partners);</li>
                        <li>data aggregation, enrichment, augmentation, processing, analytics and research; and</li>
                        <li>the design, implementation, maintenance and hosting of any database on our behalf;</li>
                    </ul>
                    <ul>
                        <li>Our professional advisers, including our accountants, auditors and lawyers;</li>
                        <li>Our insurers;</li>
                        <li>To our commercial partners, including our broadcasters (only non-sensitive personal information to be provided);</li>
                        <li>Governmental bodies; and</li>
                        <li>Third party service providers.</li>
                        <li>Our service providers are not authorised by us to use or disclose your personal information except as necessary to perform services on our behalf or to comply with legislation. We may also disclose your personal information for the purposes of safety and security to relevant law enforcement bodies or for the purposes of planning the event including, for example, traffic and transport planning purposes.</li>
                    </ul>
                    <p><span>Transfer of Information Overseas</span></p>
                    <p>We may disclose your information to third-party service providers who may be located (and so your personal information may be disclosed) overseas, including in India, the USA and the UK, and other countries from time to time. In such circumstances, we will use our best endeavours to ensure such parties are subject to a law, binding scheme or contract which effectively upholds principles for fair handling of the information that is suitably similar to the Australian Privacy Principles.</p>
                    <p>Due to the nature of the internet, if you are visiting our website(s) your communications will inevitably result in the transfer of information across international boundaries. In particular, information obtained will be stored by our data hosting provider on dedicated servers located in multiple international jurisdictions. Our data hosting provider offers reliability and security by storing data in high-performance data centres in multiple international locations.</p>
                    <p><span>Direct marketing</span></p>
                    <p>We will use non-sensitive personal information to provide better services and for marketing purposes.</p>
                    <p>If you do not wish to receive e-mail, SMS or posted offers from us, you may opt-out by using the link provided. Alternatively, you may advise us at any time that you do not wish to receive e-mail, SMS or posted offers.</p>
                    <p>Other disclosures</p>
                    <p>In addition, we may also disclose personal information:</p>
                    <ul>
                        <li>with your express or implied consent;</li>
                        <li>when required or authorised by law;</li>
                        <li>to an enforcement body when reasonably necessary; or</li>
                        <li>to lessen or prevent a threat to an individual or public health or safety.</li>
                    </ul>
                    <p><span>Our websites</span></p>
                    <p>When you visit ADA Ninjaz websites, our systems may record certain information about your use of those websites (such as which web pages you visit and the time and date of your visit). We use this information to help analyse and improve the performance of our websites.</p>
                    <p>Our websites including www.adaninjaz.com</p>
                    <p>In addition, we may use &ldquo;cookies&rdquo; on ADA Ninjaz websites. Cookies are small text files that help a website to remember your preferences and improve your experience of using that website. Using cookies is standard practice for most large websites. In some cases, the cookies that we use may collect some personal information about you. We will treat this information in the same way as other personal information we collect about you (*This information is subject to this Privacy Policy and will be treated in the same way as other personal information we collect about you). If you prefer, you may be able to disable cookies on your internet browser. However, if you do so, you will not be able to enjoy the enhanced user experience that our cookies offer.</p>
                    <p>Web beacons are images that originate from a third-party site to track visitor activities. We use web beacons to track the visiting patterns and browsing habits of individuals accessing our website. This information is not intended to and will generally not include any personal information, and we will not use the information to identify any individual.</p>
                    <p>We may also collect Internet Protocol (IP) addresses relating to you when you access and use the websites. IP addresses are assigned to computers on the internet to uniquely identify them within the global network of computers that makes up the internet. We may collect and manage IP addresses for internet session management and security purposes.</p>
                    <p>We have engaged Google Analytics to help analyse how our websites are used. This analysis is performed using data collected from the websites. However, this information is not intended to and will generally not include any personal information.</p>
                    <p>To opt-out of being tracked by Google Analytics across all websites, visit this link:&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.</p>
                    <p><span>Google DoubleClick DART Cookie</span></p>
                    <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL &ndash; https://policies.google.com/technologies/ads</p>
                    <p><span>​</span></p>
                    <p><span>Our Advertising Partners</span></p>
                    <p>Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has its own Privacy Policy for its policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
                    <p><span>Google</span></p>
                    <p>https://policies.google.com/technologies/ads</p>
                    <p><span>Accessing Cookies in Various Browsers</span></p>
                    <p>The following links explain how to access cookie settings in various browsers:</p>
                    <p><a href="https://support.mozilla.com/en-US/kb/Enabling%20and%20disabling%20cookies">Cookie settings in Firefox</a></p>
                    <p><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Cookie settings in Internet Explorer</a></p>
                    <p><a href="https://www.google.com/support/chrome/bin/answer.py?answer=95647">Cookie settings in Google Chrome</a></p>
                    <p><a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&amp;locale=en_US">Cookie settings in Safari (OS X)</a></p>
                    <p><a href="https://support.apple.com/en-us/HT201265">Cookie settings in Safari (iOS)</a></p>
                    <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en&amp;oco=0">Cookie settings in Android</a></p>
                    <p>Websites linked to any of the ADA Ninjaz websites are not subject to our privacy standards, policies or procedures. We cannot take any responsibility for the collection, use, disclosure or security of any personal information that you provide to a third-party website.</p>
                    <p><span>Accuracy of your personal information</span></p>
                    <p>ADA Ninjaz takes all reasonable steps to ensure that the personal information we collect, use and disclose is accurate, complete and up-to-date.</p>
                    <p>However, we rely on the accuracy of personal information as provided to us both directly and indirectly. You are encouraged to regularly review and update your personal information. If you find that the personal information we hold about you is inaccurate, incomplete or out-of-date, please contact us immediately (our contact details are set out below) and we will take reasonable steps to ensure that it is corrected.</p>
                    <p><span>How can you access the personal information we hold about you?</span></p>
                    <p>Subject to some exceptions provided by law, you have the right to access your personal information.</p>
                    <p>For security purposes, if you would like to access personal information that we hold about you, we require you to put your request in writing. If we do not allow you access to any part of the personal information we hold about you, we will tell you why.</p>
                    <p>We will not charge you for lodging a request to access your personal information, but we may charge you for our reasonable costs in supplying you with access to this information.</p>
                    <p><span>Opt-Out/Unsubscribe</span></p>
                    <p>You can easily unsubscribe from our communications at any time by contacting us using the contact details specified in this Privacy Policy, accessing the ADA Ninjaz Preference Centre or by following the instructions contained in an e-mail. It may take some time to effect an opt-out or an unsubscribe so you may still receive communications from us for several weeks after your request is received.</p>
                    <p>For the avoidance of doubt, opting&ndash;out or unsubscribing yourself from a mailing list of an ADA Ninjaz product or service will ensure your removal from that particular mailing list but will not guarantee your removal from other mailing lists that ADA Ninjaz may store your details in as a result of your engagement with a different service or product.</p>
                    <p><span>Contacting Us</span></p>
                    <p>If you would like to access the personal information that ADA Ninjaz holds about you, have any questions in relation to this Privacy Policy or have a complaint, you can contact us using the contact details specified below.</p>
                    <p>ADA Ninjaz takes all privacy complaints seriously and will investigate your complaint in a confidential manner. You will be informed of the outcome of the investigation within a reasonable period of time.</p>
                    <p>You can contact ADA Ninjaz by emailing info@adaninjaz.com</p>
                    <p>If we are unable to resolve your complaint or you are unhappy with the outcome, you can contact the Office of Australian Information Commissioner via its enquiries line 1300 363 992 or website&nbsp;<a href="https://www.oaic.gov.au/">http://www.oaic.gov.au/</a>&nbsp;to lodge a complaint.</p>
                    <p><span>Changes to this Privacy Policy</span></p>
                    <p>From time to time, we may need to change this Privacy Policy to reflect our changing business practices. We will notify you of any amendments by posting an updated version of this Privacy Policy on our website (www.adaninjaz.com). This Privacy Policy was last updated on 19 August 2019.</p>
                    <p><span>Rights under the UK Data Protection Act 2018 and General Data Protection Regulation (GDPR)</span></p>
                    <p>This section only applies to you if you are using our website(s) or interacting with ADA Ninjaz from the UK or European Union or otherwise benefit from rights as a data subject under the UK Data Protection Act 2018 (GDPR).</p>
                    <p>Please note for the purposes of this Privacy Policy, ADA Ninjaz is the data controller and is responsible for your personal information collected in connection with this Privacy Policy.</p>
                    <p>If you wish to exercise any of the rights set out below, please contact us on the details below:</p>
                    <ul>
                        <li>Emailing: info@adaninjaz.com</li>
                    </ul>
                    <ul>
                        <li>If this section applies to you, you may have certain additional rights in relation to our handling of your personal information, including the following rights:</li>
                    </ul>
                    <ul>
                        <li>to request access to your personal information</li>
                        <li>to correct your personal information</li>
                        <li>to erase your personal information</li>
                        <li>to restrict the processing of your personal information;</li>
                        <li>to transfer your personal information;</li>
                        <li>to object to the processing of personal information;</li>
                        <li>to withdraw consent;</li>
                        <li>to obtain a copy of personal information safeguards used for transfers outside your jurisdiction; and</li>
                        <li>to lodge a complaint with your local supervisory authority.</li>
                    </ul>
                    <p><span>​</span></p>
                    <p><span>No Fee Usually Required</span></p>
                    <p>You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
                    <p><span>What we may need from you</span></p>
                    <p>If you wish to access any of these rights, we may ask you for additional information to confirm your identity and for security purposes, in particular before disclosing personal information to you. We reserve the right to refuse to provide this additional information if your request is manifestly unfounded or excessive.</p>
                    <p><span>Time Limit to Respond</span></p>
                    <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                    <p>Subject to legal and other permissible considerations, we will make every reasonable effort to honour your request promptly or inform you if we require further information in order to fulfil your request.</p>
                    <p>We may not always be able to address your request fully, for example if it would impact the duty of confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way.</p>
                    <p>We will take steps to ensure that prior to profiling your information for a legitimate interest, that our legitimate interest is not overridden by your own interests or fundamental rights and freedoms. A legitimate interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us. If you are a data subject with rights under the GDPR you may have rights to object to us profiling your personal information.</p>
                    <p><span>Right to access, correction or erasure of personal information</span></p>
                    <p>You have a right to request access to the personal information that we hold on you. You also have the right to request that we correct any inaccurate personal information. We may seek to verify the accuracy of the personal information before rectifying it. You can also request that we erase your personal information in limited circumstances where:</p>
                    <ul>
                        <li>it is no longer needed for the purposes for which it was collected; or</li>
                        <li>you have withdrawn your consent (where the data processing was based on consent); or</li>
                        <li>following a successful exercise of the right to object (see right to object below); or</li>
                        <li>it has been processed unlawfully.</li>
                    </ul>
                    <ul>
                        <li>We are not required to comply with your request to erase personal information if the processing of your personal information is necessary:</li>
                    </ul>
                    <ul>
                        <li>for compliance with a legal obligation; or</li>
                        <li>for the establishment, exercise or defence of legal claims.</li>
                    </ul>
                    <ul>
                        <li>Right to restrict the processing of your personal information<br />You can ask us to restrict your personal information, but only where:</li>
                    </ul>
                    <ul>
                        <li>it&rsquo;s accuracy is contested, to allow us to verify its accuracy; or</li>
                        <li>the processing is unlawful, but you do not want it erased; or</li>
                        <li>it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise or defend legal claims; or</li>
                        <li>it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise or defend legal claims; or</li>
                    </ul>
                    <ul>
                        <li>We can continue to use your personal information following a request for restriction, where:</li>
                    </ul>
                    <ul>
                        <li>we have your consent; or</li>
                        <li>to establish, exercise or defend legal claims; or</li>
                        <li>to protect the rights of another natural or legal person.</li>
                    </ul>
                    <p><span>Right to transfer your personal information</span></p>
                    <p>You can ask us to provide your personal information to you in a structured, commonly used, machine-readable format, or you can ask to have it transferred directly to another data controller, but in each case only where:</p>
                    <ul>
                        <li>the processing is based on your consent or on the performance of a contract with you; and</li>
                        <li>the processing is carried out by automated means.</li>
                    </ul>
                    <p><span>Right to object to the processing of your personal information</span></p>
                    <p>You can object to any processing of your personal information which has our legitimate interests as its legal basis, if you believe your fundamental rights and freedoms outweigh our legitimate interests.</p>
                    <p>If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms.</p>
                    <p><span>Right to withdraw consent</span></p>
                    <p>Where we have collected your personal information via consent, you have the right to withdraw consent at any time. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
                    <p><span>Right to lodge a complaint with your local supervisory authority</span></p>
                    <p>You have a right to lodge a complaint with your local supervisory authority if you have concerns about how we are processing your personal information.</p>
                    <p>We ask that you please attempt to resolve any issues with us first, although you have a right to contact your supervisory authority at any time.</p>
                </Col>

            </Row>



          
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <WhyCardanoBottom state={state} dispatch={dispatch} stateTwo={stateTwo} dispatchTwo={dispatchTwo} />


        </div>
    )
}

export default Legal