import React, { useState, useEffect, Component, useMemo, useCallback, useReducer, useRef } from 'react'
import { Col, Row, Card, Button, Input, List, Typography } from 'antd';

// import '..WhyCardano/WhyCardano'
import whyHeroImg from '../images/whyHeroImg.jpg'

import { motion } from 'framer-motion'



const WhyCardanoHero = ({ state, dispatch, stateTwo, dispatchTwo }) => {


    const whyHero = useRef();

    useEffect(() => {
        if (whyHero !== state?.refs?.whyHero) {
            dispatch({ type: 'setRefs', payload: { ...state?.refs, 'whyHero': whyHero } })
        }
    }, [state])



    const container = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.5
            }
        },
        show: {
            opacity: 1,
            transition: {
                duration: 1.5,
                delayChildren: 0.2,
                staggerDirection: 1,
                staggerChildren: 0.5
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            x: -800,
            // y: -200,
            rotate: 2,
            transition: {
                type: "spring",
                duration: 1,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            x: 0,
            // y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                duration: 1,
                bounce: 0.1,

            }
        }
    }




    return (
        <div ref={whyHero} className='whyHeroSection' style={{
            height: '80vh',
        }}>
            <Row justify='center' style={{ height: '100%' }}>
                <Col span={20} style={{ height: '100%' }}>
                    <Row justify='start' align='middle' style={{ height: '100%' }}>
                        <motion.div
                            align='left'
                            variants={container}
                            initial={'hidden'}
                            whileInView={'show'}
                            viewport={{ once: false, amount: 0.1 }}
                        // animate='show'
                        // transistion={{ staggerChildren: 0.5, delayChildren: 0.5 }}
                        >

                            <Col >
                                <motion.div variants={item} >

                                    <Row>
                                        <h1 className='whyh1' style={{ paddingRight: '20px' }}>6 REASONS</h1>
                                    </Row>
                                </motion.div>
                                <motion.div variants={item} >

                                    <Row>
                                        <h1 className='whyh1' style={{ color: '#F9C6CB', paddingRight: '20px' }}>WHY</h1>
                                        <h1 className='whyh1' style={{ paddingRight: '20px' }}>I </h1>
                                        <h1 className='whyh1' style={{ paddingRight: '20px' }}>CHOSE </h1>
                                    </Row>
                                </motion.div>
                                <motion.div variants={item} >

                                    <Row>
                                        <h1 className='whyh1'> CARDANO</h1>
                                    </Row>
                                </motion.div>

                            </Col>
                        </motion.div>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default WhyCardanoHero


