import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

const AudioPlayer = ({ url, name }) => {

    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    // references
    const audioPlayer = useRef();   // reference our audio component
    const progressBar = useRef();   // reference our progress bar
    const animationRef = useRef();  // reference the animation

    useEffect(() => {
        const seconds = Math.floor(audioPlayer.current.duration);
        setDuration(seconds);
        // progressBar.current?.max = seconds;
    }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);


    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioPlayer.current.play();
            animationRef.current = requestAnimationFrame(whilePlaying)
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    const whilePlaying = () => {
        progressBar.current.value = audioPlayer.current.currentTime;
        changePlayerCurrentTime();
        animationRef.current = requestAnimationFrame(whilePlaying);
        // console.log('progress', `${progressBar.current?.value / audioPlayer.current.duration * 100}%`)
        // console.log('current?.value', progressBar.current?.value )
        // console.log( 'duration', duration )
    }

    const changeRange = () => {
        audioPlayer.current.currentTime = progressBar.current.value;
        changePlayerCurrentTime();
    }


    const changePlayerCurrentTime = () => {
        // progressBar.current.style.setProperty('width', `${progressBar.current.value / duration * 100}%`)
        setCurrentTime(progressBar.current.value);
    }

    return (
        <div>
           
            <p className='AppExplainText' style={{ marginBottom: '0px' }}>  {name ?? name}  </p>
            <audio ref={audioPlayer} src={url} preload="metadata"></audio>
            <Row
                gutter={[10, 10]}
                justify='space-between'
                align='middle'
                style={{ height: '70px', backgroundColor: 'white', borderRadius: '100px' }}
            >
                {isPlaying ?
                    <div
                        className='audioPauseButton'
                        style={{ marginLeft: '10px', width: '90px', height: '60px', backgroundColor: '#FFC0CF', borderRadius: '60px' }}
                        onClick={() => togglePlayPause()}
                    >


                        <Row align='middle' justify='center' style={{ height: '100%', }}>
                            <Col>

                                <PauseOutlined style={{ fontSize: 50, color: 'white' }} color='white' />


                            </Col>
                        </Row>

                    </div>
                    :
                    <div
                        className='audioPlayButton'
                        style={{ marginLeft: '10px', width: '90px', height: '60px', backgroundColor: '#FFC0CF', borderRadius: '60px' }}
                        onClick={() => togglePlayPause()}
                    >


                        <Row align='middle' justify='center' style={{ height: '100%', }}>
                            <Col>

                                <CaretRightOutlined style={{ fontSize: 50, color: 'white' }} color='white' />

                            </Col>
                        </Row>

                    </div>
                }

                <Col flex="auto" style={{ marginLeft: '10px', marginRight: '20px' }}>
                    <div className='audioProgressBar' style={{ height: '20px', backgroundColor: '#D9D9D9', borderRadius: '20px' }}>

                        <div ref={progressBar} style={{ height: '100%', backgroundColor: 'pink', borderRadius: '20px', width: `${progressBar.current?.value / audioPlayer?.current?.duration * 100}%` }}>

                        </div>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default AudioPlayer