import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Card, Button, Input, List, Divider, Space, Dropdown, Anchor } from 'antd';
import { motion } from 'framer-motion'


const IntroSectionThree = ({ state, dispatch, stateTwo, dispatchTwo }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(null);


    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);
    useEffect(() => {
        if (screenSize > 1368) {
            setActiveMenu(false);
        }
        else if (screenSize > 1000) {
            setActiveMenu(true);
        }
        else if (screenSize > 700) {
            setActiveMenu(true);
        }
        else if (screenSize > 500) {
            setActiveMenu(true);
        }
        else if (screenSize > 300) {
            setActiveMenu(true);
        }
        else {
            setActiveMenu(true);
        }
    }, [screenSize]);


    const introThree = useRef(null);

    useEffect(() => {
        if (introThree !== state.refs.introThree) {
            dispatch({ type: 'setRefs', payload: { ...state.refs, 'introThree': introThree } })
        }
    }, [state])


    const container = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 2.5,
                delayChildren: 1.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        },
        show: {
            opacity: 1,
            transition: {
                duration: 1.5,
                delayChildren: 1.2,
                staggerDirection: 1,
                staggerChildren: 0.2
            }
        }
    }

    const item = {
        hidden: {
            opacity: 0,
            // x: -100,
            y: 50,
            rotate: 2,
            scale: 0,
            transition: {
                delay: 1,
                type: "spring",
                duration: 5.5,
                bounce: 0.1,

            }

        },
        show: {
            opacity: 1,
            // x: 0,
            y: 0,
            rotate: 0,
            scale: 1,
            transition: {
                delay: 1,
                type: "spring",
                duration: 1.5,
                bounce: 0.1,

            }
        }
    }




    return (
        <div className='introSectionThreeMainDiv'
        // style={{ height: '80vh' }}
        >
            <div className='introSectionThreeSubOne' style={{ height: '100%' }} >
                <div ref={introThree} className='introSectionThreeSubTwo' style={{ height: '100%' }}>

                    <Row justify='center' align='middle' style={{ height: '100%', paddingTop: '150px', paddingBottom: '150px' }}>
                        <Col xs={22} sm={22} md={22} lg={20} xl={20}>
                            <br />
                            <br />
                            <br />
                            <br />

                            {/* <h3 className='introSectionText' style={{ paddingRight: '20px' }}>My Elsa Jean Community Pass will give you</h3> */}

                            <motion.div
                                variants={container}
                                initial={'hidden'}
                                whileInView={'show'}
                                viewport={{ once: false, amount: 0 }}
                            >
                                {/* <motion.div variants={item}> */}

                                    <Row justify='center'>
                                        {/* <h3 className='introSectionText' style={{paddingRight: '20px'}}>And</h3> */}
                                        <h3 className='introSectionText' style={{ paddingRight: '20px' }}>  My Elsa Jean   </h3>
                                        <h3 className='introSectionText' style={{ color: '#c6e0f9', paddingRight: '20px' }}> Community Pass </h3>
                                        <h3 className='introSectionText' style={{}}>  will give you   </h3>

                                    </Row>
                                {/* </motion.div> */}
                                {/* <motion.div variants={item}> */}

                                    <Row justify='center'>
                                        {/* <h3 className='introSectionText' style={{paddingRight: '20px'}}>And</h3> */}
                                        <h3 className='introSectionText' style={{ paddingRight: '20px', color: '#F9C6CB' }}>private access </h3>
                                        <h3 className='introSectionText' style={{ paddingRight: '20px' }}>  to  </h3>
                                        <h3 className='introSectionText' > my little secrets. </h3>

                                    </Row>
                                {/* </motion.div> */}
                            </motion.div>
                            {/* <br />
                            <h3 className='introSectionText'>Come in and enjoy the Elsa community…</h3>
                            <br />
                            <h3 className='introSectionText'>My Elsa Jean Community Pass will give you private access to my little secrets.</h3> */}
                            <br />
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    )
}

export default IntroSectionThree